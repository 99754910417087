import { Component, Input, Inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ParentUIViewInject, UIRouterModule, UIView } from '@uirouter/angular';


@Component({
	selector: 'video-search-sidebar-header',
	standalone: true,
	imports: [
		TranslateModule,
		UIRouterModule
	],
	template: `
		<header class="theme-primary right-sidebar-content-header box-flex">
			<h4 flex="fill" [attr.id]="titleId">{{ title }}:</h4>
			<a
				class="theme-primary-txt margin-right-10"
				[attr.aria-label]="'Event_CloseSidebarPanel' | translate"
				[uiSref]="parentState"
			>
				<span class="glyphicons remove_2"></span>
			</a>
		</header>
	`
})
export class VideoSearchSidebarHeaderComponent {
	@Input() public title: string;
	@Input() public titleId: string;

	public parentState: string;

	constructor(
		@Inject(UIView.PARENT_INJECT) private parent: ParentUIViewInject
	) {
		this.parentState = this.parent.context.parent.name;
	}
}
