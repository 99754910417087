import { Component } from '@angular/core';
import { BrowseUserPlaylistsComponent } from './BrowseUserPlaylists.Component';

import searchStyles from 'rev-portal/media/search/Search.module.less';
@Component({
	selector: 'browse-user-playlists-tiles',
	templateUrl: './BrowseUserPlaylistsTiles.Component.html',
	host: {
		layout: 'column',
		'layout-wrap': 'false',
		flex: 'fill',
		'[class]': 'rootStyles.tilesRoot'
	}
})
export class BrowseUserPlaylistsTilesComponent extends BrowseUserPlaylistsComponent {
	public readonly searchStyles = searchStyles;

}
