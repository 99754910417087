import { Component, Input, OnInit, AfterViewInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { sortBy } from 'underscore';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ColumnState, GridApi, GridReadyEvent, RowDragEndEvent, RowDragEnterEvent, RowDragLeaveEvent, RowDragMoveEvent, RowSelectedEvent, SortChangedEvent } from '@ag-grid-community/core';

import { ApprovalStatus } from 'rev-shared/media/MediaConstants';
import { PlaylistService } from 'rev-shared/media/Playlist.Service';
import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialogAngular.Component';
import { UtilService } from 'rev-shared/util/Util.Service';

import { MediaStateService } from '../MediaState.Service';

import { orderBy } from 'rev-shared/util/SortUtil';
import { IThumbnailCellRendererParams } from '../table/VbMediaGridThumbnailCellRenderer.Component';

@Component({
	selector: 'playlist-details-table',
	templateUrl: './PlaylistDetailsTable.Component.html',
	host: {
		layout: 'column',
		'layout-wrap': 'false',
		flex: 'fill',
		class: 'height-full'
	}
})
export class PlaylistDetailsTableComponent implements OnInit, OnDestroy {
	@Input() public canEdit: boolean;
	@Input() public hasMediaEditAuth: boolean;
	@Input() public playlist: any;
	@Input() public userId: string;

	@ViewChild('removeVideosConfirmation')
	public removeVideosConfirmation: VbConfirmationDialogComponent;

	public all: boolean;
	private onKeydownHandler: any;
	private previousSort: any;
	public selectedVideos: any[];
	public sort: any;
	public approvalStatusOptions: typeof ApprovalStatus;
	private gridApi: GridApi;
	public indexColumnSortNotActive: boolean;

	public readonly rowDragText = params => {
		return this.indexColumnSortNotActive ? this.Translate.instant('Drag_Disabled') : params?.rowNode?.data?.title || params.defaultTextValue;
	};
	public readonly watchPlaylistRendererParams: IThumbnailCellRendererParams = {
		getCellCfg: field => ({
			uiSref: 'portal.video-playlist-playback',
			uiSrefParams: { videoId: field.id, playlistId: this.playlist.id },
			ariaLabel: this.Translate.instant('Media_Playlist_StartPlayingPlaylist', { 0: this.playlist.name })
		})
	};

	public readonly profilePicRendererParams = {
		getProfilePicUrl: video => video.owner?.profileImageUri,
		cssClass: 'util-white-space-normal'
	};

	constructor(
		private $state: StateService,
		private MediaStateService: MediaStateService,
		private PlaylistService: PlaylistService,
		private Util: UtilService,
		private Translate: TranslateService
	) {}

	public ngOnInit(): void {
		this.all = false;
		this.selectedVideos = [];
		this.sort = {
			property: 'playlistIndex',
			ascending: true
		};
		this.approvalStatusOptions = ApprovalStatus;

		this.onKeydownHandler = (event: any) => this.onKeydown(event);
		document.addEventListener('keydown', this.onKeydownHandler);
		this.MediaStateService.searchResultsState.getSelectedCount = () => this.selectedVideos?.length;

		if (this.playlist.featured) {
			this.playlist.name = this.Translate.instant('Media_Videos_Playlists_FeaturedVideos');
		}
	}

	public ngOnDestroy(): void {
		document.removeEventListener('keydown', this.onKeydownHandler);
	}

	public hasEditVideoAuth(video: any) {
		return video && (video.editAcl || []).includes(this.userId);
	}

	public removeSelectedVideos(): void {
		const playlist = this.playlist;
		const videos = playlist.videos;
		const videosToKeep = videos.filter((video: any) => !video.selected);

		if (videosToKeep.length === videos.length) {
			return;
		}

		this.removeVideosConfirmation.open().result
			.then(() => {
				playlist.videos = videosToKeep;
				this.updateSelectedVideos();

				this.savePlaylist({
					id: playlist.id,
					name: playlist.name,
					videos: videosToKeep
				})
					.catch(() => {
						videos.forEach((video: any) => {
							if (video.selected) {
								video.selected = false;
								video.error = true;
							}
						});
						this.previousSort = null;
						playlist.videos = videos;
						this.updateSelectedVideos();
					});
			})
			.catch(err => {
				if (err) {
					console.error(err);
				}
			});
	}

	public savePlaylistOrder(fromIndex: number, toIndex: number): void {
		const previousVideos: any[] = this.playlist.videos?.map(video => video);


		function moveInArray(arr: any[], fromIndex: number, toIndex: number) {
			var element = arr[fromIndex];
			arr.splice(fromIndex, 1);
			arr.splice(toIndex, 0, element);
		}

		moveInArray(this.playlist.videos, fromIndex, toIndex);

		this.playlist.videos = this.playlist.videos.map((v: any, index: number) => {
			index = this.sort.ascending ? index + 1 : this.playlist.videos.length + 1 - index;
			v.playlistIndex = index;
			return v;
		});

		this.gridApi.setRowData(this.playlist.videos);
		this.gridApi.clearFocusedCell();

		this.savePlaylist(this.playlist)
			.catch(() => {
				this.previousSort = null;
				this.playlist.videos = previousVideos;
			});
	}

	public onGridReady(params: GridReadyEvent) {
		this.gridApi = params.api;
	}

	public onRowDragEnd(event: RowDragEndEvent) {
		const movingNode = event.node;
		const overNode = event.overNode;
		const rowNeedsToMove = movingNode && overNode && movingNode !== overNode;

		if (rowNeedsToMove) {
			const fromIndex = this.findIndex(movingNode.data);
			const toIndex = this.findIndex(overNode.data);
			this.savePlaylistOrder(fromIndex, toIndex);
		}
	}

	public onSortChanged(event: SortChangedEvent) {
		var colState = event.columnApi.getColumnState() || [];
		this.indexColumnSortNotActive = colState.some((col: ColumnState) => col.colId !== 'playlistIndex' && col.sort);

		const suppressRowDrag = !this.canEdit || this.indexColumnSortNotActive;
		this.gridApi.setSuppressRowDrag(suppressRowDrag);
	}

	public sortPlaylists(sortProperty: string): void {
		if (this.sort.property === sortProperty) {
			this.sort.ascending = !this.sort.ascending;
		}

		this.sort.property = sortProperty;
		this.playlist.videos = orderBy(this.playlist.videos, video => video[this.sort.property], this.sort.ascending);
	}


	public onRowSelected(event: RowSelectedEvent): void {
		if (!this.canEdit) {
			return;
		}
		this.toggleSelection(event.node.data, event.node.isSelected());
	}

	public toggleSelection(video: any, isSelected: boolean): void {
		video.selected = isSelected;

		this.updateSelectedVideos();
	}

	private onKeydown(event: any): void {
		if (event.which === 46) {
			this.removeSelectedVideos();
		}
	}

	private savePlaylist(playlist: any): any {
		const sortedVideoIds: string[] = sortBy(playlist.videos, 'playlistIndex').map((v: any) => v.id);

		if (this.playlist.isFeatured) {
			return this.PlaylistService.modifyFeaturedVideos(sortedVideoIds);
		}

		return this.PlaylistService.modifyPlaylist({
			playlistId: playlist.id,
			name: playlist.name,
			videoIds: sortedVideoIds
		});
	}

	private updateSelectedVideos(): void {
		this.selectedVideos = this.playlist.videos.filter((video: any) => video.selected);
	}

	private findIndex(data: any): number {
		return this.playlist.videos?.findIndex(video => video.id === data.id);
	}
}
