<video-search-sidebar-header [title]="'Media_Search_Filters' | translate" [titleId]="'filtersForm'"></video-search-sidebar-header>

<form class="sidebar-form contentWrapper vb-scrollbar" #form="ngForm" (submit)="onChange()" aria-labelledby="filtersForm">
	<collapsable-section-v2 *ngIf="!filters.title?.blockUserUpdates" [headerTitle]="'Title' | translate" [startClosed]="true">
		<div class="form-group">
			<label class="control-label" for="titleSearch">{{ 'Media_Search_Filters_TitleSearch' | translate }}:</label>
			<input type="text" id="titleSearch" name="titleSearch" class="form-control" [(ngModel)]="filters.title.value" (ngModelChange)="onChange()">
		</div>
	</collapsable-section-v2>

	<collapsable-section-v2 *ngIf="!filters.description?.blockUserUpdates" [headerTitle]="'Description' | translate" [startClosed]="true">
		<div class="form-group">
			<label class="control-label" for="descriptionSearch">{{ 'Media_Search_Filters_DescriptionSearch' | translate }}:</label>
			<input type="text" id="descriptionSearch" name="descriptionSearch" class="form-control" [(ngModel)]="filters.description.value" (ngModelChange)="onChange()">
		</div>
	</collapsable-section-v2>

	<collapsable-section-v2 *ngIf="!filters.ownerUserId?.blockUserUpdates && !isGuestUser" #ownerSection [headerTitle]="'Media_Videos_Owner' | translate" [startClosed]="true">
		<div class="form-group acl">
			<insight name="owner" (ngModelChange)="onChange()" [(ngModel)]="filters.ownerUserId.value" [insightOptions]="ownerInsightOptions" [optionTemplate]="accessEntityOptionRow" [assignedTemplate]="accessEntityAssignedRow" [hidden]="!ownerSection.expanded">
			</insight>
		</div>
	</collapsable-section-v2>

	<collapsable-section-v2 *ngIf="!filters.whenUploaded?.blockUserUpdates" [headerTitle]="'Media_Search_Filters_UploadDate' | translate" [startClosed]="true">

		<div class="form-group" [ngClass]="{ 'has-error': form?.uploadFromDate?.invalid }">
			<label class="control-label" id="fromDateSearch">{{ 'From' | translate }}</label>
				<div role="group" aria-labelledby="fromDateSearch">
			 	<div class="vb-date-time-input">
					<vb-ui-date-picker flex="75" name="uploadFromDate" [(ngModel)]="filters.whenUploaded.value.from" #datePicker="ngModel" (ngModelChange)="onChange()" [accessibilityLabel]="'DateTimePicker_DateInput' | translate">
					</vb-ui-date-picker>
				</div>
			</div>
			<div class="error-field">
				<label [hidden]="!(form.uploadFromDate?.error.date)">{{ 'ValidDateRequired' | translate }}</label>
			</div>
		</div>

		<div class="form-group" [ngClass]="{ 'has-error': form.uploadToDate?.invalid }">
			<label class="control-label" id="toDateSearch">{{ 'To' | translate }}</label>
				<div role="group" aria-labelledby="toDateSearch">
				<div class="vb-date-time-input">
					<vb-ui-date-picker flex="75" name="uploadToDate" [(ngModel)]="filters.whenUploaded.value.to" #datePicker="ngModel" (ngModelChange)="onChange()" [accessibilityLabel]="'DateTimePicker_DateInput' | translate">
					</vb-ui-date-picker>
				</div>
			</div>

			<div class="error-field">
				<label [hidden]="!(form.uploadToDate?.error.vbMin)">{{ 'Media_Search_Filters_DateRangeError' | translate }}</label>
				<label [hidden]="!(form.uploadToDate?.error.date)">{{ 'ValidDateRequired' | translate }}</label>
			</div>
		</div>

	</collapsable-section-v2>


	<ng-template #accessEntityOptionRow let-item="item" let-insight="insight">
		<access-entity-option-row [item]="item" [insight]="insight"></access-entity-option-row>
	</ng-template>

	<ng-template #accessEntityAssignedRow let-item="item" let-insight="insight">
		<access-entity-assigned-row [item]="item" [insight]="insight"></access-entity-assigned-row>
	</ng-template>

	<collapsable-section-v2 *ngIf="!filters.uploaderUserId?.blockUserUpdates && !isGuestUser" #uploaderSection [headerTitle]="'Media_Videos_Uploader' | translate" [startClosed]="true">
		<div class="form-group acl">
			<insight name="uploader" (ngModelChange)="onChange()" [(ngModel)]="filters.uploaderUserId.value" [insightOptions]="uploaderInsightOptions" [optionTemplate]="accessEntityOptionRow" [assignedTemplate]="accessEntityAssignedRow" [hidden]="!uploaderSection.expanded">
			</insight>
		</div>
	</collapsable-section-v2>

	<collapsable-section-v2 *ngIf="!filters.userTags?.blockUserUpdates && !isGuestUser" #userTagsSection [headerTitle]="'InThisVideo' | translate" [startClosed]="true">
		<div class="form-group acl">
			<insight name="userTags" (ngModelChange)="onChange()" [(ngModel)]="filters.userTags.value" [insightOptions]="userTagsInsightOptions" [optionTemplate]="accessEntityOptionRow" [assignedTemplate]="accessEntityAssignedRow" [hidden]="!userTagsSection.expanded">
			</insight>
		</div>
	</collapsable-section-v2>

	<collapsable-section-v2 *ngIf="!filters.isActive?.blockUserUpdates" [headerTitle]="'Status' | translate" [startClosed]="true">
		<div class="form-group">
			<select class="form-control" [attr.aria-label]="'Status' | translate " [(ngModel)]="filters.isActive.value" name="status" (ngModelChange)="onChange()">
				<option [value]="true">
					{{'Active' | translate}}
				</option>
				<option [value]="false">
					{{'Inactive' | translate}}
				</option>
			</select>
		</div>
	</collapsable-section-v2>

	<collapsable-section-v2 *ngIf="!filters.isLive?.blockUserUpdates" [headerTitle]="'VideoType' | translate" [startClosed]="true">
		<div class="form-group">
			<select class="form-control" [attr.aria-label]="'VideoType' | translate " name="videoType" [(ngModel)]="filters.isLive.value" (ngModelChange)="onChange()">
				<option [value]="false">
					{{'VOD' | translate}}
				</option>
				<option [value]="true">
					{{'Live' | translate}}
				</option>
			</select>
		</div>
	</collapsable-section-v2>

	<collapsable-section-v2 *ngIf="!filters.is360?.blockUserUpdates" [headerTitle]="'Filter360Video' | translate" [startClosed]="true">
		<div class="form-group">
			<select class="form-control" [attr.aria-label]="'Filter360Video' | translate " name="filter360Video" [(ngModel)]="filters.is360.value" (ngModelChange)="onChange()">
				<option [value]="true">
					{{'FilterOptionIs360Video' | translate}}
				</option>
				<option value="false">
					{{'FilterOptionNot360Video' | translate}}
				</option>
			</select>
		</div>
	</collapsable-section-v2>

	<collapsable-section-v2 *ngIf="!filters.tags?.blockUserUpdates" #tagsSection [headerTitle]="'Tags' | translate" [startClosed]="true">
		<div id="tags" uiControl>
			<vb-tags-input *ngIf="tagsSection.expanded" name="tags" [placeholder]="'TagsInputPlaceholder' | translate" [(ngModel)]="filters.tags.value" (ngModelChange)="onChange()">
			</vb-tags-input>
		</div>
	</collapsable-section-v2>

	<collapsable-section-v2 *ngIf="!filters.categories?.blockUserUpdates && isCategoryFilterEnabled" #categoriesSection [headerTitle]="'Media_Search_Filters_CategoryFilter' | translate" [startClosed]="true">
		<div id="categories" uiControl>
			<vb-categories-tags-input *ngIf="categoriesSection.expanded" [placeholder]="'Media_Videos_Settings_CategoriesInputPlaceholder' | translate" name="categories" [(ngModel)]="filters.categoryIds.value" [whitelist]="categories" (ngModelChange)="onChange()">
			</vb-categories-tags-input>
		</div>
	</collapsable-section-v2>

	<collapsable-section-v2 *ngIf="teams?.length > 1 && !filters.teamIds?.blockUserUpdates" #teamsSection [headerTitle]="'Title_Teams' | translate" [startClosed]="true">
		<div class="form group">
			<vb-teams-tags-input *ngIf="teamsSection.expanded" [placeholder]="'Media_Videos_Settings_TeamsInputPlaceholder' | translate" name="teamsTags" [whitelist]="teams" [(ngModel)]="filters.teamIds.value" (ngModelChange)="onTeamTagsChanged($event)">
			</vb-teams-tags-input>
		</div>
	</collapsable-section-v2>

	<collapsable-section-v2 *ngIf="!filters.unlisted?.blockUserUpdates && isUnlistVideoEnabled && !isGuestUser" [headerTitle]="'Unlisted' | translate" [startClosed]="true">
		<div class="form-group">
			<select class="form-control" name="unlistedVideo" [attr.aria-label]="'Unlisted' | translate" [(ngModel)]="filters.unlisted.value" (ngModelChange)="onChange()">
				<option [value]="true">
					{{'FilterOptionUnlistedVideo' | translate}}
				</option>
				<option value="false">
					{{'FilterOptionNotUnlistedVideo' | translate}}
				</option>
			</select>
		</div>
	</collapsable-section-v2>

	<button class="hidden-submit" type="submit" aria-hidden="true" tabindex="-1"></button>
	<div class="clear-btn-wrapper">
		<button type="button" (click)="clear()" class="btn btn-primary">{{ 'Media_Search_Filters_ClearAll' | translate }}</button>
	</div>
</form>
