<div *ngIf="!hasMixedLegalHoldValues" class="legal-hold sidebar" dropdown [insideClick]="true">

	<button type="button" role="tab" tabindex="-1" class="dropdown-toggle legacySidebarBtn sidebar-item-light" [attr.aria-label]="'Admin_LegalHold' | translate" [attr.id]="VideoResultsSidebarButton.BULK_EDIT_DELETE_LEGALHOLD" [tooltip]="'Admin_LegalHold' | translate" [adaptivePosition]="false" [container]="'body'" [placement]="'left'" dropdownToggle>
		<span class="glyphicons lock"></span>
	</button>

	<div *dropdownMenu role="menu" class="dropdown-menu sidebar-menu align-right upload-media">
		<div role="group" class="list-group sidebar-dropdown util-nobreak" [attr.aria-label]="'LegalHold_Menu' | translate">
			<button *ngIf="hasVideosSelected && !isOnLegalHold" type="button" role="menuitem" class="btn-transparent dropdown-item menu-link" (click)="lockVideos()">
				{{ 'Video_LegalHold_Apply' | translate }}
			</button>
			<button *ngIf="hasVideosSelected && isOnLegalHold" type="button" role="menuitem" class="btn-transparent dropdown-item menu-link" (click)="removeLockFromVideos()">
				{{ 'Video_LegalHold_Remove' | translate }}
			</button>
			<span role="menuitem" class="dropdown-item menu-link" [hidden]="hasVideosSelected">
				{{ 'Video_LegalHold_NoVideos' | translate }}
			</span>
		</div>
	</div>

</div>
