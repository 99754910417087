import {
	AfterViewInit,
	Component,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	EventEmitter,
	ViewChild,
	ElementRef,
	Renderer2
} from '@angular/core';

// https://github.com/metafizzy/flickity
import Flickity from 'flickity-bg-lazyload'; // internally requires and augments flickity core
import 'flickity/dist/flickity.css';

import { FlickityExtended } from './FlickityExtendend';

import styles from './vb-flickity-carousel.module.less';

@Component({
	selector: 'vb-flickity-carousel',
	host: {
		'[class]': 'styles.root'
	},
	template: `
		<div [ngClass]="[
				styles.flickityCarouselContainer,
				containerClass
			]"
			#container>
			<ng-content></ng-content>
		</div>
	`
})
export class VbFlickityCarouselComponent implements OnInit, AfterViewInit, OnChanges {
	@Input() public carouselItemModel: any[]; // Optional. On change will trigger a refresh of the carousel.
	@Input() public flickityConfig: any;
	@Output() public onReady = new EventEmitter();
	@Output() public onSelect = new EventEmitter();
	@ViewChild('container') private containerElement: ElementRef<HTMLElement>;

	constructor(
		private renderer: Renderer2
	) { }

	private flickity: FlickityExtended;
	public readonly styles = styles;
	public containerClass: string;

	public ngOnInit(): void {
		this.containerClass = this.flickityConfig?.context?.containerClass ?? '';
	}

	public ngAfterViewInit(): void {
		window.setTimeout(() => {
			this.flickity =
				new Flickity(this.containerElement.nativeElement, {
					cellSelector: '.carousel-cell',
					contain: true,
					draggable: true,
					...this.flickityConfig,
					on: {
						ready: () => {
							this.renderer.setStyle(this.containerElement.nativeElement, 'opacity', '1');

							if (this.flickityConfig?.groupCells) {
								this.renderer.setStyle(this.containerElement.nativeElement, 'height', '100%');
							}

							this.applyPrevNextButtonStyle();
							setTimeout(() => this.updateVisibleFocus(), 100);
						},
						change: () => this.updateVisibleFocus()
					}
				}) as FlickityExtended;

			this.initFlickityEvents();

			this.onReady.emit({
				carousel: this
			});
		}, 500);
	}

	private updateVisibleFocus() {
		this.flickity?.cells?.forEach((node: any) => {
			node.element.querySelectorAll('a:not([carouselFocusOverride=true]), button:not([carouselFocusOverride=true])')
				.forEach((childElement: HTMLElement) => childElement.setAttribute('tabIndex', '-1'));
		});
		this.flickity?.selectedElements?.forEach((e: HTMLElement) => {
			e.querySelectorAll('a:not([carouselFocusOverride=true]), button:not([carouselFocusOverride=true])')
				.forEach((childElement: HTMLElement) => childElement.removeAttribute('tabIndex'));
		});
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.carouselItemModel) {
			this.onCarouselItemModelChange();
		}
	}

	public get selectedIndex(): number {
		return this.flickity ?
			this.flickity.selectedIndex :
			-1;
	}

	public get isPrevButtonDisabled(): boolean {
		if(this.flickity){
			const options = this.flickity.options;
			return options.wrapAround || this.selectedIndex === 0;
		}
	}

	public get isNextButtonDisabled(): boolean {
		if(this.flickity){
			const options = this.flickity.options;
			return options.wrapAround || this.selectedIndex === this.flickity.slides.length - 1;
		}
	}

	public movePrevious(): void {
		this.flickity.previous(false, false);
	}

	public moveNext(): void {
		this.flickity.next(false, false);
	}

	public refreshCarousel(): void {
		const currentCellElements = this.flickity.getCellElements();

		// remove any stray existing carousel items
		if (currentCellElements?.length) {
			this.flickity.remove(currentCellElements);
		}

		const el: HTMLElement = this.containerElement.nativeElement;
		const cells = Array.from(el.querySelectorAll(this.flickity.options.cellSelector));
		cells.forEach(cell => cell.remove());
		this.flickity.append(cells);

		// reset page
		this.flickity.select(0);

		// update the slides model
		window.setTimeout(() => this.flickity.resize(), 100);
	}

	public select(index: number): void {
		this.flickity.select(index);
	}

	private initFlickityEvents(): void {
		if(this.onSelect){
			this.flickity.on('select', () => window.setTimeout(() => this.onSelect.emit()));
		}
	}

	private onCarouselItemModelChange(): void {
		if (this.flickity && this.carouselItemModel) {
			this.refreshCarousel();
		}
	}

	private applyPrevNextButtonStyle(): void {
		const { prevBtnClass, nextBtnClass } = this.flickityConfig?.context ?? {};
		const element = this.containerElement.nativeElement;

		if (prevBtnClass) {
			element.querySelector('.flickity-prev-next-button.previous')?.classList?.add(prevBtnClass);
		}

		if (nextBtnClass) {
			element.querySelector('.flickity-prev-next-button.next')?.classList?.add(nextBtnClass);
		}
	}
}
