<vb-title [title]="playlist.name"></vb-title>
<div vbUiStretch>
	<vb-ui-sidebar-layout #sidebarLayout class="height-full" flex="fill" [sidebarConfig]="sidebarConfig" [themed]="true" [noBorder]="true" (onSidebarToggle)="activeSidebarButtonId = $event.activeSidebarButtonId">
		<div slot="mainContent">
			<vb-media-embed-preview *ngIf="isPreviewEnabled; else mainContent" class="vb-scrollbar" [ngClass]="styles.embedPreviewContent">
			</vb-media-embed-preview>

			<ng-template #mainContent>
				<div class="fadein-effect container-fluid vb-scrollbar" [ngClass]="[
						!isTableViewMode ? styles.tilesMainContent : styles.listMainContent
					]">

					<playlist-details-table *ngIf="isTableViewMode; else tiles" class="box-block margin-top-10" [canEdit]="canEdit" [hasMediaEditAuth]="hasMediaEditAuth" [playlist]="playlist" [userId]="userId">
					</playlist-details-table>

					<ng-template #tiles>
						<playlist-detail-tiles class="box-block margin-top-10 margin-bottom-20" [hasMediaEditAuth]="hasMediaEditAuth" [mediaFeatures]="mediaFeatures" [playlist]="playlist" [userId]="userId">
						</playlist-detail-tiles>
					</ng-template>
				</div>
			</ng-template>
		</div>
		<div slot="sidebarPanel">
			<playlist-details-sidebar [ngClass]="styles.playlistSidebar" [selectedButtonId]="activeSidebarButtonId" [embedsEnabled]="embedsEnabled" [playlistId]="playlist.id" [playlistName]="playlist.name" [playlistEmbedUrl]="playlistEmbedUrl" [playlistPlaybackUrl]="playlistPlaybackUrl">
			</playlist-details-sidebar>
		</div>
	</vb-ui-sidebar-layout>
</div>
