<div class="carousel-container">
	<button type="button" class="btn-carousel btn-carousel-prev" [attr.aria-label]="'Carousel_Previous' | translate" [disabled]="carousel.isPrevButtonDisabled" (click)="carousel.movePrevious()">
		<span class="glyphicon glyphicon-chevron-left"></span>
	</button>

	<vb-flickity-carousel class="flickity-carousel" #carousel [carouselItemModel]="videos" [flickityConfig]="flickityConfig">
		<div *ngFor="let video of videos" class="carousel-cell" [ngClass]="video.id === currentVideoId ? 'selected' : ''">
			<button type="button" class="cell-title" [attr.aria-label]="video.title" [tooltip]="video.title" [container]="'body'" [placement]="'top'" (click)="onVideoClick(video.id)">
				<div class="img-wrap">
					<div class="fixed-ratio" [style.background-image]="getBackground(video)">
						<span *ngIf="video.duration >= 0" class="duration-overlay">
							{{ video.duration | vbTimespan }}
						</span>
					</div>
				</div>
				<span>
					{{ video.title }}
				</span>
			</button>
		</div>
	</vb-flickity-carousel>

	<button type="button" class="btn-carousel btn-carousel-next" [attr.aria-label]="'Carousel_Next' | translate" [disabled]="carousel.isNextButtonDisabled" (click)="carousel.moveNext()">
		<span class="glyphicon glyphicon-chevron-right"></span>
	</button>
</div>
