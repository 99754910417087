<div class="container-fluid" [style.background-color]="team.bgColor" [ngClass]="styles.teamHeader">

	<div class="hidden-xs col-sm-2 col-md-2" [ngClass]="styles.teamLogoContainer" [hidden]="!(team.thumbnailUri)">
		<img class="img-responsive" *ngIf="team.thumbnailUri" [src]="team.thumbnailUri" [alt]="'ThumbnailForAria' | translate : { '0': team.name }"/>
	</div>
	<div class="col-xs-6 col-md-8" [ngClass]="[
			styles.teamHeaderContent
		]">

		<div [style.color]="team.themeColor">
			<h1 [ngClass]="styles.teamName">
				{{team.name}}
			</h1>

			{{ 'Teams_LastUpdated' | translate }}: {{team.lastUpdated | vbDateTimeMedium}}
		</div>

		<div *ngIf="MediaStateService.showEditTeam && team.canEditTeam" class="margin-top-10">
			<a class="util-underline" uiSref="portal.team-edit" [uiParams]="{ accountId: accountId, teamId: team.id, canEdit: team.canEditTeam }" [style.color]="team.themeColor">
				{{'EditTeam' | translate }}
			</a>
			<button type="button" class="btn-link margin-left-16 util-underline" [ngClass]="styles.verticalDivider" [style.color]="team.themeColor" (click)="onDeleteTeam()">
				{{'DeleteTeam' | translate }}
			</button>
			<vb-confirmation-dialog [title]="'DeleteTeam' | translate" [cancelText]="'Cancel' | translate" [actionText]="'Delete' | translate" [themed]="true">
				<div>{{ 'DeleteTeam' | translate }}: <b>{{team.name}}</b></div>
				<div class="margin-top-15">{{ 'Teams_DeleteNote' | translate }}</div>
			</vb-confirmation-dialog>
		</div>
	</div>
	<media-upload-btn class="col-md-2 col-xs-2" *ngIf="MediaStateService.showUploadToTeam && team.canUploadVideo" [teamId]="team.id" [btnText]="'UploadToTeam' | translate" [ngClass]="styles.uploadToTeam">
	</media-upload-btn>
</div>
