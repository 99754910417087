<a class="theme-primary-txt" [ngClass]="[
		styles.titleAnchor,
		config.coverFullCell && video?.innerHits?.length ? '' : 'height-full'
	]" [uiSref]="config.uiSref" [uiParams]="config.uiSrefParams" [attr.aria-label]="config.ariaLabel">
	<span [ngClass]="styles.title" [title]="video.title">{{ video.title }}</span>
	<unlisted-video-indicator [ngClass]="styles.unlistedComp" [isVideoUnlisted]="video.unlisted" [hasMediaEditAuth]="videoEditAuth" [showOnlyIcon]="true">
	</unlisted-video-indicator>
	<video-360-indicator class="margin-left-5" *ngIf="video.is360">
	</video-360-indicator>
</a>

<speech-search-results-list #speechSearchList *ngIf="video?.innerHits?.length" [videoId]="video.id" [results]="video.innerHits" [query]="context.query" [maxShownByDefault]="context?.isSmallSize ? 1 : 2" [resultExpanded]="video.subtitleExpanded" (rowExpanded)="toggleRowExpand($event)">
</speech-search-results-list>
