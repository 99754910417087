<ul class="media-box-list padding-top-10">
	<li uiSrefActive="active" role="menuitem">
		<a class="dropdown-item media-video-item theme-accent-txt" uiSref="portal.media.all" [uiOptions]="{ inherit: false }" uiSrefActive="active">
			<span class="text-label">{{ 'Media_AllVideos' | translate }}</span>
		</a>
	</li>
	<li *ngIf="MediaMenuService?.isCategoriesEnabled" uiSrefActive="active" role="menuitem" [ngClass]="{'animated fadeIn': MediaMenuService.isFetching}">
		<a class="dropdown-item media-video-item theme-accent-txt" uiSref="portal.media.browse" [uiOptions]="{ inherit: false }" uiSrefActive="active">
			<span class="text-label">{{ 'Media_BrowseCategories' | translate }}</span>
		</a>
	</li>
	<li *vbAuthorizationKey="'media.videoOwner'" uiSrefActive="active" role="menuitem">
		<a class="dropdown-item media-video-item theme-accent-txt" uiSref="portal.media.uploads" [uiOptions]="{ inherit: false }" uiSrefActive="active">
			<span class="text-label">{{ 'Media_UserVideos' | translate }}</span>
		</a>
	</li>
	<li uiSrefActive="active" role="menuitem">
		<a class="dropdown-item media-video-item theme-accent-txt" uiSref="portal.media.my-playlists" [uiOptions]="{ inherit: false }" uiSrefActive="active">
			<span class="text-label">{{ 'Media_Menu_Playlists' | translate }}</span>
		</a>
	</li>
	<li *ngIf="MediaMenuService.isMySubscriptionEnabled" uiSrefActive="active" role="menuitem" [ngClass]="MediaMenuService.isFetching ? 'animated fadeIn' : ''">
		<a class="dropdown-item media-video-item theme-accent-txt" uiSref="portal.media.my-subscriptions" [uiOptions]="{ inherit: false }" uiSrefActive="active">
			<span class="text-label"> {{ 'Media_MySubscriptions' | translate }} </span>
		</a>
	</li>
	<li *vbAuthorizationKey="'media.add'" uiSrefActive="active" role="menuitem">
		<a class="dropdown-item media-video-item theme-accent-txt" uiSref="portal.media.expirations" [uiOptions]="{ inherit: false }" uiSrefActive="active">
			<span class="text-label">{{ 'Media_Expirations' | translate }}</span>
		</a>
	</li>
</ul>
