<textarea [ngClass]="styles.txtArea" readonly="readonly" rows="4" [value]="linkUrl" [attr.aria-label]="'Link' | translate" vbUiTextArea vbSelectOnClick #clipboardTarget>
</textarea>

<div [ngClass]="styles.actionWrapper" layout-wrap>

	<button type="button" [ngClass]="styles.toolbarBtn" [ngxClipboard]="clipboardTarget" *ngxClipboardIfSupported>
		{{ 'Media_Videos_Sharing_Copy' | translate }}
	</button>

	<button *ngIf="enableSpark" type="button" [attr.aria-label]="'Media_Videos_Sharing_Spark_Title' | translate" [ngClass]="styles.btn" (click)="shareToSparkInternal()" [tooltip]="'Media_Videos_Sharing_Spark_Title' | translate " [placement]="'bottom'" [container]="'body'">
		<span [ngClass]="styles.sparkLogo"></span>
	</button>

	<button type="button" [attr.aria-label]="'Media_Videos_Sharing_Email' | translate" [ngClass]="styles.btn" (click)="onEmailClick()" [tooltip]="'Media_Videos_Sharing_Email' | translate" [placement]="'bottom'" [container]="'body'">
		<span [ngClass]="styles.phoneLogo" class="glyphicons envelope"></span>
	</button>

	<section *ngIf="!hideLinkOptions" display="flex" layout="column" [ngClass]="styles.embedOptionWrapper">

		<div flex="fill" class="margin-bottom-10">
			{{ 'Media_Videos_Embed_LinkOptions' | translate }}
		</div>

		<div layout="row" class="margin-bottom-10" flex-align="center">

			<vb-toggle-button name="showStartAt" [toggleId]="'showStartAt'" [ariaLabel]="'Media_Videos_Sharing_StartAt' | translate" [(ngModel)]="showStartAt" (change)="onToggleStartAt()">
			</vb-toggle-button>
			<label>{{ 'Media_Videos_Sharing_StartAt' | translate }}</label>

			<div *ngIf="showStartAt" [ngClass]="styles.startAt">
				<input type="text" [attr.aria-label]="'Media_Videos_Sharing_StartAt' | translate" name="startAt" #startAtInput="ngModel" [(ngModel)]="startAt" vbTimespanInput [includeHours]="true" vbUiTextInput [vbMin]="0"/>
				<div *ngIf="startAtInput.invalid" role="alert" vbUiErrorMessage>
					<div *ngIf="startAtInput.errors.timespan">
						{{ 'Media_Videos_Sharing_InvalidStartAt' | translate : { '0': 'hh:mm:ss' } }}
					</div>
				</div>
			</div>

		</div>

		<div layout="row">
			<vb-toggle-button name="showVideoOnly" [toggleId]="'ShowVideoOnly'" [ariaLabel]="'Media_Videos_Sharing_LinkType' | translate" [(ngModel)]="isVideoOnlyLink">
			</vb-toggle-button>
			<label for="showVideoOnly" [ngClass]="styles.label" flex="fill">
				{{ 'Media_Videos_Sharing_LinkType' | translate }}
				<vb-ui-tooltip [iconName]="'circle_question_mark'" [placement]="'top'" [text]="'Media_Videos_Sharing_LinkType_Tooltip' | translate">
				</vb-ui-tooltip>
			</label>
		</div>

		<div layout="row" class="margin-top-10">
			<vb-toggle-button name="autoplayToggle" [toggleId]="'AutoplayToggle'" [ariaLabel]="'Media_Autoplay' | translate" [(ngModel)]="isAutoplay">
			</vb-toggle-button>
			<label for="autoplayToggle" [ngClass]="styles.label">
				{{ 'Media_Autoplay' | translate }}
			</label>
		</div>

		<div layout="row">
			<vb-ui-checkbox *ngIf="video?.password?.length" [(ngModel)]="showPassword">
				{{ 'Password' | translate }}
			</vb-ui-checkbox>
		</div>

	</section>
</div>
