import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { NgModel } from '@angular/forms';

import { Subscription } from 'rxjs';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import { EmbedPreviewService } from 'rev-shared/embedPreview/EmbedPreview.Service';
import { IImageComponentMetadata } from 'rev-shared/ui/image-selector/ImageSelector.Contract';
import { MaxWidthSmallMediaQuery } from 'rev-shared/ui/size/Size.Constants';
import { MediaQueryService } from 'rev-shared/ui/size/MediaQuery.Service';
import { addQueryParams } from 'rev-shared/util/Util.Service';
import { isApple } from 'rev-shared/util/UserAgentUtil';
import { formatUrlTimespan } from 'rev-shared/date/DateFormatters';
import { clamp, mapObject } from 'rev-shared/util';

import styles from 'rev-shared/media/shareLink/vb-ui-share-link.module.less';

const videoSizes = {
	small: 560,
	medium: 640,
	large: 853
};

export enum Menus {
	Controls = 'controls',
	Layout = 'layout',
	Playback = 'playback',
	Styling = 'styling'
}

@Component({
	selector: 'vb-ui-share-embed',
	templateUrl: './VbUiShareEmbed.Component.html',
	host: {
		'[class]': 'hostCls'
	}
})
export class VbUiShareEmbedComponent implements OnInit, OnChanges, OnDestroy {
	@Input() public defaultAutoplay: boolean;
	@Input() public playbackPosition: number;
	@Input() public video: any;
	@Input() public playlist: any;
	@ViewChild('widthInput') public widthInput: NgModel;
	@ViewChild('startAtInput') public startAtInput: NgModel;

	public readonly styles = styles;
	public readonly menus = Menus;
	public readonly hostCls = isApple() ? 'ua-apple' : '';

	public imageComponentMetadata: IImageComponentMetadata = {
		requiredValidation: false,
		showResetButton: true,
		readOnly: false
	};

	public controls = {
		centerButtons: true,
		closedCaptions: true,
		fullscreen: true,
		playBar: true,
		settings: true
	};

	public playback = {
		autoplay: false,
		forceClosedCaptions: false,
		hideChapters: false,
		loopVideo: false
	};

	public readonly layouts = [
		{ name: 'row', title: 'Layout_Filmstrip', icon: 'vb-icon-playlist-row' },
		{ name: 'grid', title: 'Layout_Grid', icon: 'vb-icon-playlist-grid' },
		{ name: 'slider', title: 'Layout_Slider', icon: 'vb-icon-playlist-slider' }
	];

	public activeMenu: string;
	public allControlsToggle: boolean = true;
	public ccEnabled: boolean;
	public chaptersEnabled: boolean;
	public embedCode: string;
	public isPopupPlayer: boolean = false;
	public showStartAt: boolean;
	public startAt: number = 0;
	public sizeName = 'medium';
	public width: number = videoSizes.medium;
	public height: number;
	public isMobile: boolean;
	public mediaQuerySubscription: Subscription;
	public layout = 'row';
	public maxGridRow: number = 5;
	public maxVideos: number;

	public accentColor: string;
	public useAccentColor: boolean;
	public primaryColor: string;
	public usePrimaryColor: boolean;
	public primaryFontColor: string;
	public usePrimaryFontColor: boolean;

	constructor(
		private MediaQueryService: MediaQueryService,
		public EmbedPreviewService: EmbedPreviewService,
		public ThemeService: ThemeService
	) { }

	public ngOnInit(): void {
		this.accentColor = this.ThemeService.accentColor;
		this.playback.autoplay = this.defaultAutoplay;

		if (this.video) {
			this.ccEnabled = this.video.closedCaptionsEnabled || this.video.subtitles.length;
			this.chaptersEnabled = this.video.chapterInfo?.chapters.length;
		} else {
			this.sizeName = 'responsive';
			this.ccEnabled = true;
		}

		this.mediaQuerySubscription = this.MediaQueryService.getObservable(MaxWidthSmallMediaQuery)
			.subscribe(isMobile => this.isMobile = isMobile);

		this.updateHeight();
		this.updateEmbedData();
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.playbackPosition && !this.startAtInput?.dirty) {
			this.startAt = this.playbackPosition;
			this.updateEmbedData();
		}
	}

	public ngOnDestroy(): void {
		this.EmbedPreviewService.clearData();
		this.mediaQuerySubscription.unsubscribe();
	}

	public resetEmbedOptions(): void {
		this.sizeName = this.video ? 'medium' : 'responsive';
		this.useAccentColor = this.usePrimaryColor = this.usePrimaryFontColor = false;
		this.accentColor = this.ThemeService.accentColor;
		this.primaryColor = this.ThemeService.primaryColor;
		this.primaryFontColor = this.ThemeService.primaryFontColor;
		this.showStartAt = false;
		this.startAt = 0;
		this.isPopupPlayer = false;
		this.allControlsToggle = true;

		this.controls.centerButtons = true;
		this.controls.playBar = true;
		this.controls.closedCaptions = true;
		this.controls.settings = true;
		this.controls.fullscreen = true;

		this.playback.autoplay = this.defaultAutoplay;
		this.playback.loopVideo = false;
		if (this.chaptersEnabled) { this.playback.hideChapters = false; }
		if (this.ccEnabled) { this.playback.forceClosedCaptions = false; }

		this.layout = 'row';
		this.maxGridRow = 5;
		this.maxVideos = undefined;

		this.activeMenu = null;
		this.updateEmbedData();
	}

	public onStartAtToggleChange(): void {
		this.startAt = this.showStartAt ? this.playbackPosition : 0;
		this.updateEmbedData();
	}

	public activateMenu(menu: string): void {
		this.activeMenu = this.activeMenu == menu ? '' : menu;
	}

	public onSizeChange(): void {
		const width = videoSizes[this.sizeName];

		if(width) {
			this.width = width;
			this.updateHeight();
		}
		this.updateEmbedData();
	}

	public onWidthSizeChange(): void {
		this.sizeName = 'custom';
		this.updateHeight();
		this.updateEmbedData();
	}

	private updateHeight(): void {
		let toolbarHeight = 0;
		if(this.playlist) {
			toolbarHeight = this.layout === 'row' ? 105
				: this.layout === 'slider' ? 80
					: 0;
		}
		//For the vertical strip layout, toolbar will be on right.
		//calculation will be: (width - toolbarWidth) * 9 / 16
		this.height = Math.round(this.width * 9 / 16) + toolbarHeight;
	}



	public onAllControlsToggleChange() {
		Object.keys(this.controls).forEach(t => this.controls[t] = this.allControlsToggle);
		this.updateEmbedData();
	}

	public checkToggle() {
		this.allControlsToggle = !Object.values(this.controls).includes(false);
		this.updateEmbedData();
	}

	private getVideoEmbedParams(): any {
		return mapObject({
			id: this.video.id,
			autoplay: this.playback.autoplay,
			startAt: this.showStartAt && formatUrlTimespan(this.startAt),
			placeholder: this.isPopupPlayer,
			accent: this.useAccentColor && this.accentColor,
			noChapters: this.playback.hideChapters && this.chaptersEnabled,
			loopVideo: this.playback.loopVideo,
			...this.getControlsParams()
		}, x => x || undefined);
	}

	private getPlaylistEmbedParams(): any {
		const useTheme = this.layout !== 'row';
		const isGrid = this.layout === 'grid';

		return mapObject({
			playlist: this.playlist.id,
			autoplay: this.playback.autoplay,
			layout: this.layout,
			maxRow: isGrid && this.maxGridRow,
			maxVideos: isGrid && this.maxVideos,
			accent: useTheme && this.useAccentColor && this.accentColor,
			primary: useTheme && this.usePrimaryColor && this.primaryColor,
			primaryFont: useTheme && this.usePrimaryFontColor && this.primaryFontColor,
			...(this.layout === 'slider' && this.getControlsParams())
		}, x => x || undefined);
	}

	private getControlsParams(): any {
		return {
			noCenterButtons: !this.controls.centerButtons,
			noCc: this.ccEnabled && !this.controls.closedCaptions,
			forceClosedCaptions: this.ccEnabled && this.playback.forceClosedCaptions,
			noFullscreen: !this.controls.fullscreen,
			noPlayBar: !this.controls.playBar,
			noSettings: !this.controls.settings,
		};
	}

	public updateEmbedData(): void {
		const params = this.video?.id ? this.getVideoEmbedParams() : this.getPlaylistEmbedParams();
		const url = addQueryParams(`${window.location.origin}/embed`, params);
		this.embedCode = this.sizeName !== 'responsive' ?
			this.getIframe(this.width, this.height, params, url) :
			`<div style="position: relative; height: 0; padding-bottom: 56.25%;">${
				this.getIframe('100%', '100%', params, url, 'position: absolute; left: 0; top: 0;')
			}</div>`;


		this.EmbedPreviewService.update({
			isPreviewEnabled: !this.isMobile,
			isResponsive: this.sizeName === 'responsive',
			width: this.width,
			height: this.height,
			src: url
		});
	}

	private getIframe(width: any, height: any, params: any, url: string, style: string = ''): string {
		return `<iframe width="${width}" height="${height}" src="${url}" style="${style || ''}" frameborder="0" allowfullscreen></iframe>`;
	}

	public selectLayout(layout: string): void {
		this.layout = layout;
		this.onSizeChange();
	}

	public onMaxRowChange(): void {
		this.maxGridRow = clamp(this.maxGridRow, 3, 5);
		this.updateEmbedData();
	}
}
