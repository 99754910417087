import { Component, Input } from '@angular/core';

import { ICategory } from 'rev-shared/media/Media.Contract';

import styles from './CategoryTile.module.less';

@Component({
	selector: 'category-tile',
	templateUrl: './CategoryTile.Component.html',
	host: {
		'[class]': 'styles.root'
	}
})
export class CategoryTileComponent {
	@Input() public category: ICategory;
//	@Input() public thumbnails: IImageLink[];

	public readonly styles = styles;
	// public links: IImageLink[];

	// public ngOnChanges(): void {
	// 	this.links = this.thumbnails.length ? Array.from({ length: 4 }).map((_, i) => (this.thumbnails?.[i] || {} as IImageLink)) : [];
	// }
}
