import { Component, Input, OnInit, ViewChild, OnDestroy, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ISidebarConfig } from 'rev-shared/ui/sidebarLayoutAngular/ISidebarConfig';
import { MediaStateService } from 'rev-portal/media/MediaState.Service';

import { EmbedPreviewService } from 'rev-shared/embedPreview/EmbedPreview.Service';

import { PlaylistDetailsTableComponent } from './PlaylistDetailsTable.Component';

import styles from './PlaylistDetail.Component.module.less';

@Component({
	selector: 'playlist-detail',
	templateUrl: './PlaylistDetail.Component.html',
	host: {
		layout: 'column',
		'layout-wrap': 'false',
		flex: 'fill'
	}
})
export class PlaylistDetailComponent implements OnInit, OnDestroy {
	@Input() public canEdit: boolean;
	@Input() public hasMediaEditAuth: boolean;
	@Input() public mediaFeatures: any;
	@Input() public playlist: any;
	@Input() public userId: string;
	@Input() public embedsEnabled: boolean;
	@Input() public playlistEmbedUrl: string;
	@Input() public playlistPlaybackUrl: string;


	public canShare: boolean;
	public isTableViewMode: boolean;
	public isPreviewEnabled: boolean;
	public readonly styles = styles;
	public activeSidebarButtonId: string;
	public sub: Subscription;

	@ViewChild(PlaylistDetailsTableComponent)
	public tableComp: PlaylistDetailsTableComponent;

	public readonly sidebarConfig: ISidebarConfig = {
		buttons: [
			{
				id: 'Sharing',
				iconClass: 'glyphicons share_alt',
				label: this.Translate.instant('Media_Sharing'),
				visible: true
			},
			{
				id: 'Delete',
				iconClass: 'glyphicons bin',
				label: this.Translate.instant('Media_Playlists_RemoveVideos'),
				onClick: () => this.removeSelectedVideos(),
				visible: true
			}
		]
	};

	constructor(
		private Translate: TranslateService,
		public MediaStateService: MediaStateService,
		public EmbedPreviewService: EmbedPreviewService,
		public zone: NgZone
	) {}

	public ngOnInit(): void {
		this.canShare = this.canEdit;
		this.isTableViewMode = this.MediaStateService.isTableViewMode;
		this.sub = this.EmbedPreviewService.data$.subscribe(d => {
			this.zone.run(() => {
				this.isPreviewEnabled = d.isPreviewEnabled;
			});
		});
	}

	public ngOnDestroy(): void {
		this.EmbedPreviewService.clearData();
		this.sub?.unsubscribe();
	}

	public removeSelectedVideos(): void {
		this.tableComp.removeSelectedVideos();
	}

}
