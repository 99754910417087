import { GridApi, GridReadyEvent, GridSizeChangedEvent, PostSortRowsParams, RowClickedEvent, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, SimpleChanges, ViewChild } from '@angular/core';
import { escape } from 'underscore';

import { IPlaylist } from 'rev-shared/media/IPlaylist';
import { VbUiDataGridComponent } from 'rev-shared/ui/dataGrid/VbUiDataGrid.Component';

import { IThumbnailCellRendererParams } from '../table/VbMediaGridThumbnailCellRenderer.Component';
import { BrowseUserPlaylistsComponent } from './BrowseUserPlaylists.Component';

import styles from './BrowseUserPlaylistsTable.module.less';

@Component({
	selector: 'browse-user-playlists-table',
	templateUrl: './BrowseUserPlaylistsTable.Component.html',
	host: {
		layout: 'column',
		'layout-wrap': 'false',
		flex: 'fill'
	}
})
export class BrowseUserPlaylistsTableComponent extends BrowseUserPlaylistsComponent {
	public readonly styles = styles;
	public rowHeight: number;
	public imgWidth: number;

	@ViewChild(VbUiDataGridComponent, { static: true })
	public grid: VbUiDataGridComponent;

	public readonly thumbnailRendererParams: IThumbnailCellRendererParams = {
		getCellCfg: field => ({
			uiSref: field.featured ? this.FEATURED_PLAYLIST_DETAIL_STATE_NAME : this.PLAYLIST_DETAIL_STATE_NAME,
			uiSrefParams: {
				playlistId: field.playlistId
			},
			ariaLabel: this.Translate.instant('Media_Playlists_GoToEditPlaylist', { 0: field.name }),
			hideThumbnailStatus: true
		})
	};

	public readonly cellAnchorInfo = {
		uiSref: params => params.data?.featured ? this.FEATURED_PLAYLIST_DETAIL_STATE_NAME : this.PLAYLIST_DETAIL_STATE_NAME,
		uiSrefParams: params => {
			if (params.data?.featured) {
				return;
			}
			return { playlistId: params.data?.playlistId };
		},
		ariaLabel: params => this.Translate.instant('Media_Playlists_GoToEditPlaylist', { 0: escape(params.data?.name) })
	};

	public readonly showCheckboxSelection = params => {
		return params && params.data && !params.data.featured;
	};

	public readonly sidebarConfig = {
		buttons: [
			{
				id: 'Delete',
				iconClass: 'glyphicons bin',
				label: this.Translate.instant('Media_Playlists_DeletePlaylists'),
				onClick: () => this.deleteSelectedPlaylists(),
				visible: true
			}
		]
	};

	public readonly videoCountFormatter = params => {
		return params?.value?.length ?? 0;
	};

	public ngAfterViewInit(): void {
		this.grid.postSortRows = (params: PostSortRowsParams<IPlaylist>) => {
			const rowNodes = params.nodes;
			// here we put featured row on top while preserving the sort order
			let nextInsertPos = 0;
			for (let i = 0; i < rowNodes.length; i++) {
				const featured = rowNodes[i].data.featured;
				if (featured) {
					rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
					nextInsertPos++;
				}
			}
		};
	}

	public onRowSelected(event: RowSelectedEvent): void {
		if (event?.node?.data?.featured) {
			return;
		}
		this.toggleSelection(event.node.data, event.node.isSelected());
	}
}
