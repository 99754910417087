<vb-title [title]="'Media_Playlists' | translate"></vb-title>

<div class="media-layout overflow-hidden" layout="column" layout-gt-md="row" flex-wrap="false" flex="fill">
	<div class="fadein-effect container-fluid vb-scrollbar" flex="fill" [ngClass]="searchStyles.tilesSearchContent">
		<div [ngClass]="rootStyles.tilesContainer">
			<playlist-tile *ngFor="let playlist of playlists" [ngClass]="rootStyles.tile" [playlist]="playlist" [thumbnails]="playlist.thumbnailUris">
			</playlist-tile>
		</div>
	</div>
</div>
