import { Component, OnInit, OnDestroy, Input, Inject, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import { SignalRHubsConnection, SignalRHubsConnectionState } from 'rev-shared/push/SignalRHubsConnection';
import { decodeQueryString } from 'rev-shared/util/Util.Service';
import { SignalRHubsConnectionToken } from 'rev-shared/push/SignalRHubsConnectionToken';
import { SignalrConnectionStatusComponent } from 'rev-shared/push/SignalRConnectionStatus.Component';
import { StateService } from '@uirouter/angular';

@Component({
	selector: 'portal-app',
	template: `
		<vb-portal-styles>
		</vb-portal-styles>
		<vb-theme-styles></vb-theme-styles>
		<vb-loading-spinner [hidden]="signalr?.pushConnectionInitialized" [block]="true" [size]="'large'"></vb-loading-spinner>
		<div ui-view  class="height-full">
		</div>
		<signalr-connection-status #signalr>
		</signalr-connection-status>

	`
})
export class PortalAppComponent implements OnInit {
	@ViewChild('signalr')
	public signalr: SignalrConnectionStatusComponent;

	constructor(
		@Inject(SignalRHubsConnectionToken) public SignalRHubsConnection: SignalRHubsConnection,
		public ThemeService: ThemeService,
		public state: StateService
	) {}

	public ngOnInit(): void {
		this.initializeSignalR();
	}

	private initializeSignalR(): void {
		const transport = decodeQueryString().transport;
		const options = {
			waitForPageLoad: false,
			reconnectRetryAttempts: 360,
			reconnectRetryDelay: 5000,
			transport
		};
		this.SignalRHubsConnection.start(options);
	}
}
