<div class="tile-item" [ngClass]="styles.tileItem">
	<a uiSref="portal.media.category-detail" [uiParams]="{categoryId: category.id}" [attr.aria-label]="'Media_Category_GoToCategory' | translate : { '0': category.isUncategorized ? ('Media_Uncategorized' | translate) : category.name }">

		<div class="fixed-ratio-wrapper grid-item-list">
			<div class="fixed-ratio">
		<!--
				<ng-container *ngIf="links.length; else noVideos;">
					<div *ngFor="let link of links" class="col-sm-6">
						<div class="image-wrapper">
							<div class="fixed-ratio-wrapper">
								<div class="fixed-ratio">
									<img
										*ngIf="link.uri; else noUri;"
										[src]="link.uri"
										[alt]="'ThumbnailForAria' | translate : { '0': link.title }"
										loading="lazy"
									/>
									<ng-template #noUri>
										<div class="no-item"></div>
									</ng-template>
								</div>
							</div>
						</div>
					</div>
				</ng-container> -->

				<ng-template #noVideos>
					<div [ngClass]="styles.dummyChild"></div>
					<div [ngClass]="styles.NoVideosYet">
						{{ 'NoVideosYet' | translate }}
					</div>
				</ng-template>

			</div>
		</div>

		<div class="tile-footer" [ngClass]="styles.tileFooter">
			<div [ngClass]="styles.title">
				{{category.isUncategorized ? ('Media_Uncategorized' | translate) : category.name}}
			</div>
		</div>

	</a>

	<div class="tile-footer" [ngClass]="styles.tileFooter" layout="row">
		<a flex="75" uiSref="portal.media.category-detail" [uiParams]="{categoryId: category.id}" [attr.aria-label]="'Media_Category_GoToCategory' | translate : { '0': category.isUncategorized ? ('Media_Uncategorized' | translate) : category.name }" [ngPlural]="category.videoCount || 0">
			<ng-template ngPluralCase="=1">{{ 'Media_OneVideo' | translate }}</ng-template>
			<ng-template ngPluralCase="other">{{ 'Media_NumVideos' | translate: { '0': (category.videoCount | number) } }}</ng-template>
		</a>

		<div display="flex" flex="25" flex-justify="end">
			<a *ngIf="category.canEdit" class="margin-right-5" uiSref="portal.edit-category" [uiParams]="{ categoryId: category.id }" [attr.aria-label]="'EditCategory' | translate">
				<span class="glyphicons cogwheel"></span>
			</a>

			<a *ngIf="category.restricted" class="glyphicons lock margin-right-5 vb-icon-center" uiSref="portal.media.category-detail" [uiParams]="{categoryId: category.id}" [attr.aria-label]="'Media_Category_GoToCategory' | translate : { '0': category.isUncategorized ? ('Media_Uncategorized' | translate) : category.name }">
			</a>

			<a class="glyphicons folder_closed margin-right-5 vb-icon-center" uiSref="portal.media.category-detail" [uiParams]="{categoryId: category.id}" [attr.aria-label]="'Media_Category_GoToCategory' | translate : { '0': category.isUncategorized ? ('Media_Uncategorized' | translate) : category.name }">
			</a>
		</div>
	</div>

</div>
