import { Component, Input } from '@angular/core';

@Component({
	selector: 'playlist-details-sidebar',
	templateUrl: './PlaylistDetailsSidebar.Component.html'
})
export class PlaylistDetailsSidebarComponent {
	@Input() public selectedButtonId: string;
	@Input() public embedsEnabled: boolean;
	@Input() public playlistId: string;
	@Input() public playlistName: string;
	@Input() public playlistEmbedUrl: string;
	@Input() public playlistPlaybackUrl: string;
}
