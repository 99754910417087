<div class="tile-item" [ngClass]="styles.tileItem">
	<a [uiSref]="playlist.featured ? FEATURED_PLAYLIST_DETAIL_STATE_NAME : PLAYLIST_DETAIL_STATE_NAME" [uiParams]="playlist.featured ? {} : { playlistId: playlist.playlistId }" [attr.aria-label]="'Media_Playlists_GoToEditPlaylist' | translate">
		<div class="fixed-ratio-wrapper grid-item-list">
			<div class="fixed-ratio">
				<ng-container *ngIf="playlist.thumbnailUris?.length; else noVideos;">
					<div *ngFor="let link of links" class="col-sm-6">
						<div class="image-wrapper">
							<div class="fixed-ratio-wrapper">
								<div class="fixed-ratio">
									<img *ngIf="link.uri; else noUri;" [src]="link.uri" [alt]="'ThumbnailForAria' | translate : { '0': link.title }"/>
									<ng-template #noUri>
										<div class="no-item"></div>
									</ng-template>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
				<ng-template #noVideos>
					<div [ngClass]="styles.dummyChild"></div>
					<div [ngClass]="styles.NoVideosYet">
						{{ 'NoVideosYet' | translate }}
					</div>
				</ng-template>
			</div>
		</div>
		<div class="tile-footer" [ngClass]="styles.tileFooter">
			<div [ngClass]="styles.title">
				{{ playlist.name }}
			</div>
		</div>
		<div class="tile-footer" [ngClass]="styles.tileFooter">
			<div [ngPlural]="playlist.thumbnailUris?.length || 0">
				<ng-template ngPluralCase="=1">{{ 'Media_OneVideo' | translate }}</ng-template>
				<ng-template ngPluralCase="other">{{ 'Media_NumVideos' | translate: { '0': (playlist.thumbnailUris?.length | number) } }}</ng-template>
			</div>
		</div>
	</a>

</div>
