import { Component, Input } from '@angular/core';

import styles from './BrowseUsersPlaylists.Component.module.less';

@Component({
	selector: 'playlist-detail-tiles',
	templateUrl: './PlaylistDetailTiles.Component.html',
	host: {
		layout: 'column',
		'layout-wrap': 'false',
		flex: 'fill'
	}
})
export class PlaylistDetailTilesComponent {
	@Input() public hasMediaEditAuth: boolean;
	@Input() public mediaFeatures: any;
	@Input() public playlist: any;
	@Input() public userId: string;

	public readonly styles = styles;
}
