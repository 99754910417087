<vb-title [title]="'Media_Playlists' | translate"></vb-title>

<vb-confirmation-dialog #deletePlaylistsConfirmation [title]="'Media_Playlists_DeletePlaylists' | translate" [themed]="true" [cancelText]="'Cancel' | translate" [actionText]="'Delete' | translate">

	<ng-container [ngPlural]="selectedPlaylistIds?.length">
		<ng-template ngPluralCase="1">{{ 'Media_DeleteOnePlaylist' | translate }}</ng-template>
		<ng-template ngPluralCase="other">{{ 'Media_DeleteNumPlaylists' | translate : { '0': selectedPlaylistIds?.length } }}</ng-template>
	</ng-container>
</vb-confirmation-dialog>

<vb-ui-sidebar-layout #sidebarLayout flex="fill" [sidebarConfig]="sidebarConfig" [themed]="true" [noBorder]="true">
	<div slot="mainContent" flex="fill" class="fadein-effect container-fluid" [ngClass]="styles.mainContent">
		<vb-media-grid-wrapper #gridWrapper [cellAnchorInfo]="cellAnchorInfo">
			<vb-ui-data-grid class="height-full" flex="fill" [rowData]="playlists" [accentedSort]="true" [defaultColumnSort]="{ colId: 'name', sort: 'asc' }" [rowHeight]="gridWrapper.rowHeight" [themed]="true" [suppressFieldDotNotation]="true" [rowSelection]="'multiple'" [suppressRowClickSelection]="true" (gridSizeChanged)="gridWrapper.gridSizeChanged($event)" (rowSelected)="onRowSelected($event)">
				<vb-ui-data-grid-column [headerCheckboxSelection]="true" [headerTooltip]="''" [checkboxSelection]="showCheckboxSelection" [headerClass]="gridWrapper.styles.chkBoxHeaderCellClass" [cellClass]="gridWrapper.styles.checkBoxCellClass" [minWidth]="gridWrapper.mediaQuery.isSmallSize ? 50 : 80" [maxWidth]="gridWrapper.mediaQuery.isSmallSize ? 50 : 80">
				</vb-ui-data-grid-column>
				<vb-ui-data-grid-column [headerName]="''" [headerTooltip]="''" [field]="'thumbnailUris'" [maxWidth]="gridWrapper.imgWidth" [minWidth]="gridWrapper.imgWidth" [cellRenderer]="'thumbnailRenderer'" [cellRendererParams]="thumbnailRendererParams">
				</vb-ui-data-grid-column>
				<vb-ui-data-grid-column [field]="'name'" [headerName]="'Title' | translate" [cellRenderer]="gridWrapper.anchorCellRenderer" [sortable]="true">
				</vb-ui-data-grid-column>
				<vb-ui-data-grid-column [field]="'thumbnailUris'" [headerName]="'Media_Playlists_VideoCount' | translate" [sortable]="true" [cellRenderer]="gridWrapper.anchorCellRenderer" [valueFormatter]="videoCountFormatter">
				</vb-ui-data-grid-column>
			</vb-ui-data-grid>
		</vb-media-grid-wrapper>
	</div>
</vb-ui-sidebar-layout>
