import { Component, Input, DoCheck } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';

import { getBaseState } from 'rev-portal/media/MediaStateDeclarations';

import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';
import { PlaylistService } from 'rev-shared/media/Playlist.Service';

import { MediaStateService, ICategoryPath, ISearchResultsState, MediaViewMode, TITLE_SORT, UPLOADER_NAME_SORT, VIDEO_SELECTION_LIMIT, TileSortOptions } from 'rev-portal/media/MediaState.Service';

import styles from './mediaSearchToolbar.module.less';

@Component({
	selector: 'vb-media-toolbar',
	templateUrl: './VbMediaToolbar.Component.html',
	host: {
		'[class]': 'styles.mediaToolbar'
	}
})
export class VbMediaToolbarComponent implements DoCheck {
	@Input() public approvalProcessTemplateCount: number;
	@Input() public team: any;
	@Input() public pendingVideoCount: number;

	public baseCategoryId: string;
	public baseCategoryIndex: number;
	public category: ICategoryPath;
	public categoryPath: ICategoryPath[];
	public isPlaylistEdit: boolean;
	public playlist: any;
	public searchResultsState: ISearchResultsState;

	public readonly styles = styles;
	public readonly videoSelectionLimit = VIDEO_SELECTION_LIMIT;

	public readonly mediaLabels = {
		playlist: ['Media_OnePlaylist', 'Media_NumPlaylists'],
		video: ['Media_OneVideo', 'Media_NumVideos']
	};

	constructor(
		public readonly $state: StateService,
		public readonly MediaStateService: MediaStateService,
		public readonly MediaFeaturesService: MediaFeaturesService,
		public readonly PlaylistService: PlaylistService,
		public readonly UIRouterGlobals: UIRouterGlobals,
		private readonly LoginRedirectService: LoginRedirectService,
	)
	{}

	public ngDoCheck(): void {
		if(this.searchResultsState !== this.MediaStateService.searchResultsState ||
			this.baseCategoryId !== this.MediaStateService.baseCategoryId) {
			this.updateMediaState();
		}
	}

	public get showPendingApproval(): boolean {
		const state = this.baseStateName;
		return !this.team &&
			this.approvalProcessTemplateCount > 0 && (
			state === 'portal.media.all' ||
			state === 'portal.media.uploads'
		);
	}

	public get baseStateName(): string {
		return getBaseState(this.UIRouterGlobals);
	}

	public get selectedCount(): number {
		return this.searchResultsState.getSelectedCount?.() ?? 0;
	}

	public updateMediaState(): void {
		const { baseCategoryId, searchResultsState } = this.MediaStateService;
		this.searchResultsState = searchResultsState || {};
		this.categoryPath = searchResultsState.categoryPath;
		this.playlist = searchResultsState.playlist;

		if(this.categoryPath) {
			this.baseCategoryIndex = baseCategoryId && this.categoryPath.findIndex(c => c.id === baseCategoryId);
			this.category = this.categoryPath[this.categoryPath.length-1];
		}
		else {
			this.baseCategoryIndex = undefined;
			this.category = undefined;
		}
	}

	public onSignInClick(): void {
		const loginLink: string = this.LoginRedirectService.getLoginLink();

		if (loginLink?.length) {
			const separator = loginLink.includes('?') ? '&': '?';
			window.location.href = `${loginLink}${separator}initiateLogin=true`;
		}
	}

	public setViewMode(viewMode: MediaViewMode): void {
		this.MediaStateService.setViewMode(viewMode);

		if (viewMode === MediaViewMode.TILES && !this.MediaStateService.isValidTileSort()) {
			this.MediaStateService.showSortRecommended ?
				this.MediaStateService.setSortField(TileSortOptions.Recommended) :
				this.MediaStateService.setSortField(TileSortOptions.Title);
			this.MediaStateService.setDefaultSortDirection();
		}
		this.$state.go('.', this.MediaStateService.getQueryParams(), { reload: true });
	}

	public toggleEditPlaylist(): void {
		this.isPlaylistEdit = !this.isPlaylistEdit;
	}

	public renamePlaylist(name): void {
		const playlist = this.searchResultsState.playlist;

		this.PlaylistService.modifyPlaylist({
			playlistId: playlist.id,
			name,
			videoIds: playlist.videos.map(v => v.id)
		})
			.then(() => this.searchResultsState.playlist.name = name)
			.catch(e => console.log('Rename Playlist Error: ', e))
			.finally(() => {
				this.isPlaylistEdit = false;
			});
	}

}
