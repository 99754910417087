
<div [ngClass]="[
		userId === video.uploaderUserId ? 'video-owner': '',
		isUploading ? 'uploading' : '',
		isUploading || isProcessing ? 'not-ready' : '' ,
		isProcessingFailed ? 'processing-error' : '',
		video.isActive === false ? 'inactive' : '',
		video?.approval?.status
			&& video?.approval?.status !== approvalStatusOptions.APPROVED
			&& video?.approval?.status !== approvalStatusOptions.REJECTED ? 'pending-approval' : '',
		video?.approval?.status === approvalStatusOptions.REJECTED ? 'rejected' : '',
		styles.tileItem
	 ]" class="tile-item">
	 <a [ngClass]="styles.imageContainer" uiSref="portal.video" [uiParams]="{videoId: video.id, config: config }" [attr.aria-label]="'Media_Video_GoToVideo' | translate : { '0' : video.title }">
		<div #fixedRatioWrapper [ngClass]="styles.thumbnailWrapper" class="fixed-ratio-wrapper">
			<a [attr.aria-label]="'Media_Video_GoToVideo' | translate : { '0' : video.title }" class="fixed-ratio group">
				<vb-ui-thumbnail-sheet-preview [ngClass]="styles.thumbnailPreview" [thumbnailUri]="video.thumbnailUri || '/shared/img/default-thumbnail.png'" [thumbnailSheetCfg]="thumbnailSheets" [width]="thumbnailWidth" [height]="thumbnailHeight" [msPerFrame]="233" [altTxt]="'ThumbnailForAria' | translate : { '0': video.title }">
				</vb-ui-thumbnail-sheet-preview>
				<video-thumbnail-status [approvalStatus]="video?.approval?.status" [spinnerSize]="'medium'" [videoIsActive]="video.isActive" [videoStatus]="video.status" [videoThumbnailUri]="video.thumbnailUri">
				</video-thumbnail-status>
				<video-text-status *ngIf="!hideVideoStatusText" [approvalStatus]="video?.approval?.status" [hasMediaEditAuth]="hasMediaEditAuth || hasEditVideoAuth" [isOnLegalHold]="video.legalHold" [isPasswordProtected]="isPasswordProtected" [isUnlisted]="video.unlisted" [isVideoOwner]="userId === video.uploaderUserId" [videoIsActive]="video.isActive">
				</video-text-status>
				<div [ngClass]="styles.videoOverlay">
					<div [ngClass]="styles.bottomOverlay">
						<div class="flex-container">
							<div flex="1"></div>
							<video-360-indicator *ngIf="video.is360"></video-360-indicator>
						</div>
						<div class="flex-container">
							<div flex="1"></div>
							<div [hidden]="video.isLive || !video.duration" [ngClass]="styles.videoDurationOverlay" class="video-duration-overlay">
								{{ video.duration | vbTimespan }}
							</div>
							<div [hidden]="!video.isLive" [ngClass]="styles.liveBadge" class="live-badge">
								{{ 'Media_Live' | translate }}
							</div>
						</div>
						<div [ngClass]="styles.progressBarWrap" *ngIf="video.session && video.duration">
							<div aria-valuemax="100" aria-valuemin="0" [attr.aria-valuenow]="(100 * video.session.time / video.duration) | number: 0 " [attr.aria-valuetext]="'Dashboard_ContinueWatching_Aria' | translate " [attr.aria-label]="'Dashboard_ContinueWatching_Aria' | translate " class="theme-accent-bg" role="progressbar" [ngClass]="styles.progressBar" [ngStyle]="{width: (100 * video.session.time / video.duration) + '%'}">
							</div>
						</div>
					</div>
				</div>
			</a>
		</div>

		<div>
			<div [ngClass]="styles.title" class="theme-primary-txt">
				{{video.title}}
			</div>

			<div class="tile-footer" [ngClass]="styles.tileFooter">
				<vb-profile-picture [altTxt]="'UserProfile_ProfilePicture' | translate" [profileUrl]="video.ownerProfileImageUri">
				</vb-profile-picture>
				<div [ngClass]="styles.videoInfo">
					<span [ngClass]="styles.ownerName">{{ownerName}}</span>
					<span>{{uploadDate | vbDateTimeMedium}}</span>
				</div>
			</div>
		</div>
	 </a>
</div>
