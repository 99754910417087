import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { CollapsableSectionAngularModule } from 'rev-shared/ui/collapsableSection/CollapsableSectionAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { PipesAngularModule } from 'rev-shared/pipes/Pipes.AngularModule';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { InsightAngularModule } from 'rev-shared/ui/insight/InsightAngular.Module';
import { InsightModule } from 'rev-shared/ui/insight/ngx/Insight.Module';
import { SharedCategoryModule } from 'rev-shared/media/category/SharedCategory.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { UnlistedVideoIndicatorAngularModule } from 'rev-shared/media/unlistedVideoIndicator/UnlistedVideoIndicator.AngularModule';
import { UtilAngularModule } from 'rev-shared/util/UtilAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';
import { VbTagsInputModule } from 'rev-shared/ui/ngTagsInput/Ngx/VbTagsInput.Module';
import { VbUiDatePickerModule } from 'rev-shared/ui/datePicker/VbUiDatePicker.Module';
import { VideoTileComponent } from 'rev-shared/ui/videoTile/VideoTile.Component';

import { VideoThumbnailStatusAngularModule } from 'rev-portal/media/videos/videoThumbnailStatus/VideoThumbnailStatus.AngularModule';

import { VideoSearchResultsTableLayoutModule } from './tableLayout/VideoSearchResultsTableLayout.Module';

import { BulkEditModule } from './bulkEdit/BulkEdit.Module';
import { CategoryTileComponent } from './CategoryTile.Component';
import { SEARCH_COMPONENTS } from './SearchResults.Component';
import { SearchFiltersSidebarComponent } from './SearchFiltersSidebar.Component';
import { TableViewColumMapPipe } from './TableViewColumnMapping.Pipe';
import { VbMediaTableAngularModule } from '../table/VbMediaTableAngular.Module';
import { VbProfilePictureModule } from 'rev-shared/ui/profile/VbProfilePicture.Module';
import { VbSearchFilterListComponent } from './VbSearchFilterList.Component';
import { Video360IndicatorModule } from 'rev-shared/media/video360Indicator/Video360Indicator.AngularModule';
import { VideoSearchResultsComponent } from './VideoSearchResults.Component';
import { VideoSearchResultsTilesComponent } from './VideoSearchResultsTiles.Component';
import { VideoSearchSidebarHeaderComponent } from './VideoSearchSidebarHeader.Component';
import { searchStates } from './StateDeclarations';

const declarations = [
	...SEARCH_COMPONENTS,
	CategoryTileComponent,
	SearchFiltersSidebarComponent,
	TableViewColumMapPipe,
	VbSearchFilterListComponent,
	VideoSearchResultsComponent,
	VideoSearchResultsTilesComponent
];

@NgModule({
	imports: [
		BsDatepickerModule,
		BulkEditModule,
		CollapsableSectionAngularModule,
		CommonModule,
		DateAngularModule,
		DialogAngularModule,
		DirectivesAngularModule,
		FormsModule,
		InfiniteScrollModule,
		InsightAngularModule,
		InsightModule,
		PipesAngularModule,
		SharedCategoryModule,
		SpinnerAngularModule,
		TooltipModule,
		TranslateModule,
		UIRouterModule.forChild({ states: searchStates }),
		UnlistedVideoIndicatorAngularModule,
		UtilAngularModule,
		ValidationModule,
		VbMediaTableAngularModule,
		VbProfilePictureModule,
		VbTagsInputModule,
		VbUiDatePickerModule,
		Video360IndicatorModule,
		VideoThumbnailStatusAngularModule,
		VideoTileComponent,
		VideoSearchResultsTableLayoutModule,
		VideoSearchSidebarHeaderComponent
	],
	declarations,
	exports: declarations
})
export class SearchModule {}
