import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { HttpClientModule } from '@angular/common/http';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { DataGridModule } from 'rev-shared/ui/dataGrid/DataGrid.Module';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { UnlistedVideoIndicatorAngularModule } from 'rev-shared/media/unlistedVideoIndicator/UnlistedVideoIndicator.AngularModule';
import { Video360IndicatorModule } from 'rev-shared/media/video360Indicator/Video360Indicator.AngularModule';
import { ThumbnailSheetPreviewModule } from 'rev-shared/ui/thumbnailSheetPreview/ThumbnailSheetPreview.Module';
import { PipesAngularModule } from 'rev-shared/pipes/Pipes.AngularModule';

import { VideoThumbnailStatusAngularModule } from 'rev-portal/media/videos/videoThumbnailStatus/VideoThumbnailStatus.AngularModule';

import { VideoSearchResultsTableComponent } from './VideoSearchResultsTable.Component';
import { VideoTitleCellRendererComponent } from './VideoTitleCellRenderer.Component';
import { VideoThumbnailCellRendererComponent } from './VideoThumbnailCellRenderer.Component';
import { SpeechSearchResultComponent } from './SpeechSearchResult.Component';
import { SpeechSearchResultsListComponent } from './SpeechSearchResultsList.Component';
import { CategoryRowRendererComponent } from './CategoryRowRenderer.Component';

const declarations = [
	CategoryRowRendererComponent,
	VideoSearchResultsTableComponent,
	VideoTitleCellRendererComponent,
	VideoThumbnailCellRendererComponent,
	SpeechSearchResultComponent,
	SpeechSearchResultsListComponent
];

@NgModule({
	imports: [
		ButtonsAngularModule,
		CommonModule,
		DataGridModule,
		PipesAngularModule,
		DateAngularModule,
		DirectivesAngularModule,
		ThumbnailSheetPreviewModule,
		TooltipModule,
		TranslateModule,
		UIRouterModule,
		UnlistedVideoIndicatorAngularModule,
		Video360IndicatorModule,
		VideoThumbnailStatusAngularModule
	],
	declarations,
	exports: declarations
})
export class VideoSearchResultsTableLayoutModule {}
