<div class="col-xs-9">

	<span class="search-filter-item" *ngFor="let filter of filters">
		<strong>{{filterNames[filter.name] | translate}}: </strong>
		{{filter.format()}}
		<button type="button" class="close theme-accent-txt" [attr.aria-label]="'Close' | translate " (click)="filter.clear()"><span aria-hidden="true">&times;</span></button>
	</span>

</div>

<div class="col-xs-3">
	<span class="pull-right" [hidden]="!showClearAllButton">
		<button type="button" class="btn btn-link theme-primary-txt" (click)="clearAll()">{{ 'Media_Search_Filters_ClearAll' | translate }}</button>
	</span>
</div>
