<vb-ui-infinite-scroll-grid flex="fill" [themed]="true" [getRows]="videoSearchResults.getTableLayoutRows" [pageSize]="pageSize" [defaultColumnSort]="columnSort" [suppressFieldDotNotation]="true" [rowSelection]="bulkEdit ? 'multiple' : undefined" [suppressRowClickSelection]="true" [rowHeight]="rowHeight" [fullWidthCellRendererFunc]="'categoryFullWidthRendererFunc'" (gridReady)="onGridReady($event)" (gridSizeChanged)="gridSizeChanged($event)" (rowSelected)="onRowSelected($event)" (rowDataUpdated)="onRowDataUpdated($event)">

	<!--Default Columns-->
	<vb-ui-data-grid-column [headerName]="'SelectAll' | translate" [headerComponent]="'headerSelectAllCheckbox'" [headerComponentParams]="selectAllHeaderParams" [checkboxSelection]="true" [headerClass]="styles.chkBoxHeaderCellClass" [cellClass]="styles.checkBoxCellClass" [hide]="hiddenColumns.checkBox" [minWidth]="isSmallSize ? 50 : 80" [maxWidth]="isSmallSize ? 50 : 80">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [headerName]="''" [field]="'thumbnailUri'" [maxWidth]="imgWidth" [minWidth]="imgWidth" [hide]="hiddenColumns.thumbnailUri" [cellRenderer]="'videoThumbnailRenderer'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [headerName]="'Title' | translate" [field]="'title'" [cellRenderer]="'videoTitleRenderer'" [minWidth]="isSmallSize ? 150 : 350" [sortable]="true" [hide]="hiddenColumns.title">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [headerName]="'Media_Videos_UploadDate' | translate" [field]="'whenUploaded'" [sortable]="true" [cellRenderer]="videoLinkCellRenderer" [hide]="hiddenColumns.whenUploaded" [minWidth]="180" [maxWidth]="220" [valueFormatter]="mediumDateTimeValueFormatter">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [headerName]="'Media_Videos_Owner' | translate" [field]="'ownerName'" [cellRenderer]="'profilePicture'" [sortable]="true" [minWidth]="100" [maxWidth]="150" [hide]="hiddenColumns.ownerName" [cellRendererParams]="profilePicRendererParams">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [headerName]="'Duration' | translate" [field]="'duration'" [sortable]="true" [hide]="hiddenColumns.duration" [minWidth]="100" [maxWidth]="120" [cellRenderer]="videoLinkCellRenderer" [valueFormatter]="videoDurationFormatter">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [headerName]="'Media_Videos_Views' | translate" [field]="'viewCount'" [sortable]="true" [hide]="hiddenColumns.viewCount" [minWidth]="80" [maxWidth]="100" [cellRenderer]="videoLinkCellRenderer" [valueFormatter]="videoViewCountFormatter">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [headerName]="'Media_Videos_LastViewedDate' | translate" [field]="'lastViewed'" [sortable]="true" [hide]="hiddenColumns.lastViewed" [minWidth]="180" [maxWidth]="220" [cellRenderer]="videoLinkCellRenderer" [valueFormatter]="mediumDateTimeValueFormatter">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [headerName]="'Media_Videos_ExpirationStatus' | translate" [field]="'deleteOnExpiration'" [sortable]="true" [hide]="hiddenColumns.deleteOnExpiration" [cellRenderer]="videoLinkCellRenderer" [valueFormatter]="deleteOnExpirationFormatter">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [headerName]="'Media_Videos_ExpirationEffectiveDate' | translate" [field]="'expiryDate'" [sortable]="true" [hide]="hiddenColumns.expiryDate" [cellRenderer]="videoLinkCellRenderer" [valueFormatter]="mediumDateValueFormatter">
	</vb-ui-data-grid-column>
</vb-ui-infinite-scroll-grid>
