<vb-confirmation-dialog #removeVideosConfirmation [title]="'Media_Playlists_RemoveVideos' | translate" [cancelText]="'Cancel' | translate" [actionText]="'Ok' | translate">
	<ng-container [ngPlural]="selectedVideos?.length">
		<ng-template ngPluralCase="1">{{ 'Media_Playlists_RemoveOneVideo' | translate }}</ng-template>
		<ng-template ngPluralCase="other">{{ 'Media_Playlists_RemoveNumVideos' | translate : { '0': selectedVideos?.length } }}</ng-template>
	</ng-container>
</vb-confirmation-dialog>

<vb-media-grid-wrapper #gridWrapper class="margin-bottom-20" [ngClass]="gridWrapper.styles.defaultCursor">
	<vb-ui-data-grid class="height-full" flex="fill" [rowData]="playlist.videos" [accentedSort]="true" [defaultColumnSort]="{ colId: 'playlistIndex', sort: 'asc' }" [rowHeight]="gridWrapper.rowHeight" [themed]="true" [suppressFieldDotNotation]="true" [rowSelection]="'multiple'" [rowDragText]="rowDragText" [suppressRowDrag]="!canEdit" [suppressRowClickSelection]="true" (gridReady)="onGridReady($event)" (gridSizeChanged)="gridWrapper.gridSizeChanged($event)" (rowSelected)="onRowSelected($event)" (sortChanged)="onSortChanged($event)" (rowDragEnd)="onRowDragEnd($event)">

		<vb-ui-data-grid-column [headerCheckboxSelection]="canEdit" [headerTooltip]="''" [checkboxSelection]="canEdit" [headerClass]="gridWrapper.styles.chkBoxHeaderCellClass" [cellClass]="gridWrapper.styles.checkBoxCellClass" [minWidth]="gridWrapper.mediaQuery.isSmallSize ? 50 : 80" [maxWidth]="gridWrapper.mediaQuery.isSmallSize ? 50 : 80">
		</vb-ui-data-grid-column>
		<vb-ui-data-grid-column [field]="'playlistIndex'" [headerName]="'Order'" [rowDrag]="true" [cellClass]="gridWrapper.styles.dragCellClass" [minWidth]="gridWrapper.mediaQuery.isSmallSize ? 130 : 150" [maxWidth]="gridWrapper.mediaQuery.isSmallSize ? 130 : 150" [headerTooltip]="'Media_Playlists_Reorder_Column_Info' | translate" [sortable]="true">
		</vb-ui-data-grid-column>
		<vb-ui-data-grid-column [headerName]="''" [field]="'thumbnailUri'" [maxWidth]="gridWrapper.imgWidth" [minWidth]="gridWrapper.imgWidth" [cellRenderer]="'thumbnailRenderer'" [cellRendererParams]="watchPlaylistRendererParams">
		</vb-ui-data-grid-column>
		<vb-ui-data-grid-column [field]="'title'" [headerName]="'Title' | translate" [minWidth]="gridWrapper.mediaQuery.isSmallSize ? 150 : 300" [cellRenderer]="'videoTitleRenderer'" [cellRendererParams]="watchPlaylistRendererParams" [sortable]="true">
		</vb-ui-data-grid-column>
		<vb-ui-data-grid-column [headerName]="'Media_Videos_UploadDate' | translate" [field]="'whenUploaded'" [sortable]="true" [minWidth]="180" [maxWidth]="220" [valueFormatter]="gridWrapper.mediumDateTimeValueFormatter">
		</vb-ui-data-grid-column>
		<vb-ui-data-grid-column [headerName]="'Media_Videos_Owner' | translate" [field]="'ownerFullName'" [sortable]="true" [minWidth]="100" [maxWidth]="150" [hide]="gridWrapper.mediaQuery.isSmallSize" [cellRenderer]="'profilePicture'" [cellRendererParams]="profilePicRendererParams">
		</vb-ui-data-grid-column>
		<vb-ui-data-grid-column [headerName]="'Duration' | translate" [field]="'duration'" [sortable]="true" [hide]="gridWrapper.mediaQuery.isSmallSize" [minWidth]="100" [maxWidth]="120" [valueFormatter]="gridWrapper.videoDurationFormatter">
		</vb-ui-data-grid-column>
		<vb-ui-data-grid-column [headerName]="'Media_Videos_Views' | translate" [field]="'viewCount'" [sortable]="true" [hide]="gridWrapper.mediaQuery.isSmallSize" [minWidth]="80" [maxWidth]="100" [valueFormatter]="gridWrapper.videoViewCountFormatter">
		</vb-ui-data-grid-column>
		<vb-ui-data-grid-column [headerName]="'Media_Videos_LastViewedDate' | translate" [field]="'lastViewed'" [sortable]="true" [hide]="gridWrapper.mediaQuery.isSmallSize" [minWidth]="180" [maxWidth]="220" [valueFormatter]="gridWrapper.mediumDateTimeValueFormatter">
		</vb-ui-data-grid-column>
	</vb-ui-data-grid>
</vb-media-grid-wrapper>
