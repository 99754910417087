import { Component, Input, OnChanges } from '@angular/core';

import { IImageLink } from 'rev-shared/media/Media.Contract';
import { IPlaylist } from 'rev-shared/media/IPlaylist';

import { FEATURED_PLAYLIST_DETAIL_STATE_NAME, PLAYLIST_DETAIL_STATE_NAME } from './StateDeclarations';

import styles from '../search/CategoryTile.module.less';

@Component({
	selector: 'playlist-tile',
	templateUrl: './PlaylistTile.Component.html',
	host: {
		'[class]': 'styles.root'
	}
})
export class PlaylistTileComponent implements OnChanges {
	@Input() public playlist: IPlaylist;
	@Input() public thumbnails: IImageLink[];

	public readonly FEATURED_PLAYLIST_DETAIL_STATE_NAME = FEATURED_PLAYLIST_DETAIL_STATE_NAME;
	public readonly PLAYLIST_DETAIL_STATE_NAME = PLAYLIST_DETAIL_STATE_NAME;
	public readonly styles = styles;
	public links: IImageLink[];

	public ngOnChanges(): void {
		this.links = this.thumbnails.length ? Array.from({ length: 4 }).map((_, i) => (this.thumbnails?.[i] || {} as IImageLink)) : [];
	}
}
