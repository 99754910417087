import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { VideoResultsSidebarButton } from 'rev-portal/media/search/VideoSearchResultsTypes';

import { DialogService } from 'rev-shared/ui/dialog/Dialog.Service';
import { MediaStateService } from 'rev-portal/media/MediaState.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VideoSelectionModelService } from 'rev-portal/media/search/bulkEdit/VideoSelectionModel.Service';
import { VideoService } from 'rev-shared/media/Video.Service';

import { noop } from 'rev-shared/util';

import './legal-hold.less';

const timeoutInMilliseconds: number = 2000;

@Component({
	selector: 'vb-legal-hold-button',
	templateUrl: './LegalHoldSidebar.Component.html'
})
export class LegalHoldSidebarComponent {
	public readonly VideoResultsSidebarButton = VideoResultsSidebarButton;

	public videos: any[];

	constructor(
		private MediaStateService: MediaStateService,
		private UserContext: UserContextService,
		public VideoSelectionModel: VideoSelectionModelService,
		private VideoService: VideoService,
		private Translate: TranslateService,
		private DialogService: DialogService
	) { }

	public get hasVideosSelected(): number {
		return this.VideoSelectionModel.selectionCount;
	}

	public get isOnLegalHold(): boolean {
		if (this.VideoSelectionModel.totalVideos) {
			return this.VideoSelectionModel.selectedVideos.some(video => video.legalHold);
		}

		return false;
	}

	public get hasMixedLegalHoldValues(): boolean {
		if (this.VideoSelectionModel.selectionCount > 0) {
			return this.VideoSelectionModel.hasMixedLegalHoldSelected;
		}
	}

	public lockVideos(): void {
		this.videos = this.VideoSelectionModel.selectedVideos.map(video => video.id);
		this.openApplyLegalHoldConfirmation()
			.then(() => {
				this.VideoService.lockVideos(this.UserContext.getAccount().id, this.videos)
					.then(videoIds => {
						videoIds.forEach(videoId => {
							const videoItem = this.VideoSelectionModel.selectedVideos.find(video => videoId === video.id);
							if (videoItem) {
								videoItem.legalHold = true;
								videoItem.isActive = false;
							}
						});
					});
			})
			.then(() => this.redirectToState())
			.catch(() => noop);
	}

	public removeLockFromVideos(): void {
		this.videos = this.VideoSelectionModel.selectedVideos.map(video => video.id);
		this.openRemoveLegalHoldConfirmation()
			.then(() => {
				this.VideoService.unlockVideos(this.UserContext.getAccount().id, this.videos)
					.then(videoIds => {
						videoIds.forEach(videoId => {
							const videoItem = this.VideoSelectionModel.selectedVideos.find(video => videoId === video.id);
							if (videoItem) {
								videoItem.legalHold = false;
								videoItem.isActive = true;
							}
						});
					});
			})
			.then(() => this.redirectToState())
			.catch(() => noop);
	}

	private redirectToState(): void {
		window.setTimeout(() => this.MediaStateService.navigateBack(), timeoutInMilliseconds);
	}

	private openApplyLegalHoldConfirmation(): Promise<any> {
		const params = {
			title: this.Translate.instant('Video_LegalHold'),
			message: this.Translate.instant('Video_LegalHold_BulkApply_Dialog_Message', { '0':  this.VideoSelectionModel.selectionCount }),
			cancelText: this.Translate.instant('Cancel'),
			actionText: this.Translate.instant('Save')
		};

		return this.DialogService.openConfirmationDialog(params).result;
	}

	private openRemoveLegalHoldConfirmation(): Promise<any> {
		const params = {
			title: this.Translate.instant('Video_LegalHold'),
			message: this.Translate.instant('Video_LegalHold_BulkRemove_Dialog_Message', { '0': this.VideoSelectionModel.selectionCount }),
			cancelText: this.Translate.instant('Cancel'),
			actionText: this.Translate.instant('Save')
		};

		return this.DialogService.openConfirmationDialog(params).result;
	}

}
