import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface PreviewData {
	isPreviewEnabled: boolean;
	isResponsive: boolean;
	width: number;
	height: number;
	src: string;
}

@Injectable({
	providedIn: 'root'
})
export class EmbedPreviewService {
	private dataSubject = new BehaviorSubject<PreviewData>({} as any);
	public readonly data$ = this.dataSubject.asObservable();

	public clearData(): void {
		this.dataSubject.next({
			isPreviewEnabled: false,
			isResponsive: false,
			width: 0,
			height: 0,
			src: ''
		});
	}

	public update(data: Partial<PreviewData>): void {
		this.dataSubject.next({
			...this.dataSubject.value,
			...data
		});
	}
}
