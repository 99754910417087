import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';

import './SpeechSearchResultsList.less';

@Component({
	selector: 'speech-search-results-list',
	templateUrl: './SpeechSearchResultsList.Component.html'
})
export class SpeechSearchResultsListComponent implements OnInit {
	@Input() public query: string;
	@Input() public results: any[];
	@Input() public videoId: string;
	@Input() public maxShownByDefault: number;
	@Input() public resultExpanded: boolean;

	@Output() public rowExpanded: EventEmitter<boolean> = new EventEmitter<boolean>();

	public shownResults: any[];

	public isExpanded: boolean;

	public ngOnInit(): void {
		this.isExpanded = this.resultExpanded || false;
		this.filterResults();
	}

	public toggleExpansion(): void {
		this.isExpanded = !this.isExpanded;
		this.filterResults();
		this.rowExpanded.emit(this.isExpanded);
	}

	private filterResults(): void {
		this.shownResults = this.isExpanded ? this.results : this.results.slice(0, this.maxShownByDefault);
	}
}
