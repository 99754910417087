import { AfterContentInit, Component, ContentChild, ElementRef, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { throttle } from 'underscore';
import { GridSizeChangedEvent } from '@ag-grid-community/core';
import { StateService } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';

import { MaxWidthSmallMediaQuery } from 'rev-shared/ui/size/Size.Constants';
import { MediaQueryService } from 'rev-shared/ui/size/MediaQuery.Service';
import { VbUiDataGridComponent } from 'rev-shared/ui/dataGrid/VbUiDataGrid.Component';
import { VbUiInfiniteScrollGridComponent } from 'rev-shared/ui/dataGrid/infiniteScroll/VbUiInfiniteScrollGrid.Component';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { formatTimespanShort } from 'rev-shared/date/DateFormatters';
import { mediumDateValueFormatter, mediumDateTimeValueFormatter } from 'rev-shared/ui/dataGrid/valueFormatters/MediumDateTimeValueFormatter';

import { IMAGE_WIDTH_LARGE, IMAGE_WIDTH_SMALL, ROW_HEIGHT_LARGE, ROW_HEIGHT_SMALL } from '../search/tableLayout/Contract';
import { VbMediaGridThumbnailCellRendererComponent } from './VbMediaGridThumbnailCellRenderer.Component';
import { VideoTitleCellRendererComponent } from './VideoTitleCellRenderer.Component';

import styles from './VbMediaGridWrapper.Component.module.less';

@Component({
	selector: 'vb-media-grid-wrapper',
	template: `
		<ng-container *ngIf="mediaQuery">
			<ng-content></ng-content>
		</ng-container>
	`,
	host: {
		'[class]': 'styles.root',
		layout: 'column',
		'layout-wrap': 'false',
		flex: 'fill'
	}
})
export class VbMediaGridWrapperComponent implements AfterContentInit {
	@Input() public cellAnchorInfo: { uiSref: (params?: any) => string, uiSrefParams: (params?: any) => any, ariaLabel: (params?: any) => string };

	@ContentChild(VbUiDataGridComponent,) public normalGrid: VbUiDataGridComponent;
	@ContentChild(VbUiDataGridComponent, { read: ElementRef }) private gridElement: ElementRef<HTMLElement>;
	@ContentChild(VbUiInfiniteScrollGridComponent) public infiniteGrid: VbUiInfiniteScrollGridComponent;
	@ContentChild(VbUiInfiniteScrollGridComponent, { read: ElementRef }) private infiniteGridElement: ElementRef<HTMLElement>;

	public readonly styles = styles;
	public readonly gridSizeChanged = throttle((event: GridSizeChangedEvent) => this.onGridSizeChanged(event), 1000);
	public readonly anchorCellRenderer = params => {
		const link = this.$state.href(this.cellAnchorInfo.uiSref(params), this.cellAnchorInfo.uiSrefParams(params));
		return `
			<div display="flex" class="height-full theme-primary-txt">
				<a href="${link}" flex=fill class="${styles.cellPseudoAnchor}" aria-label="${this.cellAnchorInfo.ariaLabel(params)}">
					${params.valueFormatted != null ? params.valueFormatted : params.value == null ? '' : params.value}
				</a>
			</div>
		`;
	};
	public readonly mediumDateTimeValueFormatter = mediumDateTimeValueFormatter;
	public readonly mediumDateValueFormatter = mediumDateValueFormatter;
	public readonly videoDurationFormatter = params => {
		const video = params?.data || {};
		return video.isLive ? this.Translate.instant('Live')
			: formatTimespanShort(video.duration) || this.Translate.instant('NotAvailable');
	};
	public readonly videoViewCountFormatter = params => {
		return params?.data?.viewCount > -1 ? params?.data?.viewCount : 0;
	};

	public grid: VbUiDataGridComponent | VbUiInfiniteScrollGridComponent;
	public imgWidth: number;
	public mediaQuery: { isSmallSize: boolean };
	public rowHeight: number;

	private isSmallSizeCopy: boolean;
	private subscriptions: Subscription = new Subscription();
	private userId: string;
	private hasMediaEditAuth: boolean;

	constructor(
		private $state: StateService,
		private MediaQueryService: MediaQueryService,
		private SecurityContext: SecurityContextService,
		private Translate: TranslateService,
		private UserContext: UserContextService,
	) {}

	public ngOnInit(): void {
		this.subscriptions.add(
			this.MediaQueryService.getObservable(MaxWidthSmallMediaQuery)
				.subscribe(isSmallSize => {
					this.mediaQuery = { isSmallSize };
					this.rowHeight = this.getRowHeight();
					this.imgWidth = this.getImageWidth();
				})
		);
		this.userId = this.UserContext.getUser().id;
		this.hasMediaEditAuth = !!this.SecurityContext.checkAuthorization('media.edit');
	}

	public ngOnDestory(): void {
		this.subscriptions.unsubscribe();
	}

	public ngAfterContentInit(): void {
		this.grid = this.normalGrid || this.infiniteGrid;
		this.isSmallSizeCopy = this.mediaQuery.isSmallSize;

		this.grid.components = {
			...this.grid.components,
			videoTitleRenderer: VideoTitleCellRendererComponent,
			thumbnailRenderer:  VbMediaGridThumbnailCellRendererComponent
		};

		this.grid.context = {
			...this.grid.context,
			getRowHeight: () => this.getRowHeight(),
			getImageWidth: () => this.getImageWidth(),
			isSmallSize: this.mediaQuery.isSmallSize,
			userId: this.userId,
			hasMediaEditAuth: this.hasMediaEditAuth
		};
		this.grid.accentedSort = true;

		this.grid.rowClassRules = {
			...(this.grid.rowClassRules || {}),
			'tableViewRow': () => true
		};

		this.grid.defaultColDef = {
			...this.grid.defaultColDef,
			cellClass: styles.cellClass,
			suppressMovable: true,
			headerClass: styles.headerClass
		};
	}

	public onGridSizeChanged(event: GridSizeChangedEvent): void {
		if (this.mediaQuery.isSmallSize === this.isSmallSizeCopy) {
			return;
		}
		this.isSmallSizeCopy = this.mediaQuery.isSmallSize;
		//Tried to use redraw and refresh cells and both didnot work in case of infinite grid.
		this.$state.reload();
	}

	public getRowHeight(): number {
		return this.mediaQuery.isSmallSize ? ROW_HEIGHT_SMALL : ROW_HEIGHT_LARGE;
	}

	public getImageWidth(): number {
		return this.mediaQuery.isSmallSize ? IMAGE_WIDTH_SMALL : IMAGE_WIDTH_LARGE;
	}
}
