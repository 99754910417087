import {
	Component,
	ElementRef,
	OnInit,
	ViewChild
} from '@angular/core';
import { map, Observable, Subscription } from 'rxjs';

import { MainHeaderPositionService } from './navigation/mainHeader/MainHeaderPosition.Service';

import styles from './Portal.module.less';

@Component({
	selector: 'portal',
	host: {
		'[class]': 'styles.portal'
	},
	template: `
		<div id="global-nav"
			class="global-nav-wrap navbar-fixed-top theme-accent-txt">
			<main-header
				[mainContent]="mainContentEl"></main-header>
		</div>
		<div
			#mainContent
			class="padding-top-1 height-full"
			ui-view
			[ngStyle]="{
				'margin-top': (headerHeight$ | async)
			}">
		</div>
		<branding-preview-banner></branding-preview-banner>
	`
})
export class PortalComponent implements OnInit {
	public headerHeight$: Observable<string>;

	public readonly styles = styles;

	@ViewChild('mainContent', { read: ElementRef, static: true })
	public mainContentEl: ElementRef;

	constructor(
		private MainHeaderPosition: MainHeaderPositionService
	) {}

	public ngOnInit(): void {
		this.headerHeight$ = this.MainHeaderPosition.height$
			.pipe(
				map(val => (val ? val - 2 : 0) + 'px')
			);
	}
}
