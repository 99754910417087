
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { debounce } from 'underscore';
import { Subscription } from 'rxjs';

import { InsightSearchHelper } from 'rev-portal/search/InsightSearchHelper.Service';
import { SearchService } from 'rev-portal/search/Search.Service';

import { ICategorySettings } from 'rev-shared/ui/categorySettings/ICategorySettings';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { NONE } from 'rev-shared/media/MediaConstants';

import { SearchFilterStateService } from './SearchFilterState.Service';
import { TABLE_DATA_MODE } from './tableLayout/Contract';

import './SearchFiltersSidebar.less';

@Component({
	selector: 'search-filters-sidebar',
	templateUrl: './SearchFiltersSidebar.Component.html',
	host: {
		class: 'container-fluid filters-sidebar'
	}
})

export class SearchFiltersSidebarComponent implements OnDestroy, OnInit {
	@Input() public accountId: string;
	@Input() public isGuestUser: boolean;
	@Input() public teams: any[];
	@Input() public categories: ICategorySettings[];
	@Input() public tableDataMode: TABLE_DATA_MODE;

	private readonly AUTO_SUBMIT_TIMER_MS: number = 2000;

	public filters: any;
	private canFilterTeams: boolean;
	private debounceCancelled: boolean;
	public isUnlistVideoEnabled: boolean;
	private noneOptionAvailable: boolean;
	public onChange: () => void;
	private searchFilterStateChangeSub: Subscription;
	public uploaderInsightOptions: any;
	public ownerInsightOptions: any;
	public userTagsInsightOptions: any;
	public selectedTeams: any[];
	public isCategoryFilterEnabled: boolean;

	constructor(
		private InsightSearchHelper: InsightSearchHelper,
		private MediaFeatures: MediaFeaturesService,
		private SearchFilterState: SearchFilterStateService,
		private SearchService: SearchService,
		private $state: StateService
	) { }

	public ngOnInit(): void {
		this.canFilterTeams = true;
		this.filters = this.SearchFilterState.clone();
		this.debounceCancelled = false;
		this.noneOptionAvailable = true;
		this.onChange = debounce(() => this.onChangeInternal(), this.AUTO_SUBMIT_TIMER_MS);
		this.teams = this.shapeTeams(this.teams);
		this.searchFilterStateChangeSub = this.SearchFilterState.change$.subscribe(() => this.updateFilters());
		this.isUnlistVideoEnabled = this.MediaFeatures.accountFeatures.enableUnlisted;
		this.isCategoryFilterEnabled = this.MediaFeatures.accountFeatures.enableCategories && this.isCategoryFilterApplicable;

		this.uploaderInsightOptions = this.InsightSearchHelper.buildAccessEntityInsightOptions({
			accountId: this.accountId,
			users: true
		});

		this.ownerInsightOptions = this.InsightSearchHelper.buildAccessEntityInsightOptions({
			accountId: this.accountId,
			users: true
		});

		this.userTagsInsightOptions = this.InsightSearchHelper.buildAccessEntityInsightOptions({
			accountId: this.accountId,
			users: true
		});
	}

	public ngOnDestroy(): void {
		this.searchFilterStateChangeSub.unsubscribe();
	}

	public clear(): void {
		this.debounceCancelled = true;
		this.canFilterTeams = true;
		this.noneOptionAvailable = true;
		this.SearchFilterState.go({}, this.$state);
		setTimeout(() => this.debounceCancelled = false, this.AUTO_SUBMIT_TIMER_MS);
	}

	private onChangeInternal(): void {
		if (!this.debounceCancelled) {
			this.submit();
		}

		this.debounceCancelled = false;
	}

	public onTeamTagsChanged(teams: any[]): void {
		if (teams?.length) {
			this.selectedTeams = this.filters.teamIds.value;
			this.onTeamAdded(teams[0]);
		} else {
			this.onTeamRemoved(this.selectedTeams[0]);
		}
	}

	public readonly onTeamAdded = (team: any): void => {
		if (team.name === NONE) {
			this.canFilterTeams = false;
		} else {
			this.noneOptionAvailable = false;
		}

		this.onChange();
	};

	public onUploadDateChange( date: any ): void {
		this.filters.whenUploaded.value = {};
	}

	public readonly onTeamRemoved = (team: any): void => {
		if (team.name === NONE) {
			this.canFilterTeams = true;
		}

		this.noneOptionAvailable = !this.filters.teamIds.value.length;

		this.onChange();
	};

	private shapeTeams(teams: any[]) {
		const teamOptions = [{ name: NONE, teamId: '' }];
		teams?.forEach(team => teamOptions.push({ teamId: team.teamId, name: team.name }));
		return teamOptions;
	}

	private submit(): void {
		this.debounceCancelled = true;
		const saniFilters = this.SearchFilterState.sanitizeFilterObject(this.filters);
		this.SearchFilterState.go(saniFilters, this.$state);
	}

	private updateFilters(): void {
		this.filters = this.SearchFilterState.clone();

		const selectedTeamsCount: number = (this.filters?.teamIds.value || []).length;

		this.noneOptionAvailable = !selectedTeamsCount;
		if (selectedTeamsCount) {
			this.canFilterTeams =
				selectedTeamsCount !== 1 ||
				this.filters?.teamIds.value[0].name !== NONE;
		} else {
			this.canFilterTeams = true;
		}
	}

	private get isCategoryFilterApplicable() : boolean {
		return this.tableDataMode !== TABLE_DATA_MODE.CategoryAndVideo;
	}
}
