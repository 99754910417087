<section *ngIf="embedsEnabled" layout="row">
	<label id="panelPlaylistShare" class="shareLabel">
		{{ 'Media_Videos_Sharing_Label' | translate }}
	</label>
	<div class="radioBtnGroup" vbUiRadioBtnGroup aria-labelledby="panelPlaylistShare">
		<button type="button" name="shareLinkOpt" [(ngModel)]="currentShareView" [vbUiRadioBtn]="PlaylistSharingViews.LINK">
			{{ 'Link' | translate }}
		</button>
		<button type="button" name="shareEmbedOpt" [(ngModel)]="currentShareView" [vbUiRadioBtn]="PlaylistSharingViews.EMBED">
			{{ 'Media_Videos_Sharing_Embed' | translate }}
		</button>
	</div>
</section>

<section layout="row">
	<vb-ui-share-link *ngIf="currentShareView === PlaylistSharingViews.LINK" class="shareView" [externalEmailBody]="playlistSharingEmailBody" [externalEmailSubject]="'Media_Videos_Playlists_Sharing_Email_Subject' | translate" [externalLinkUrl]="playlistPlaybackUrl" [hideLinkOptions]="true">
	</vb-ui-share-link>

	<vb-ui-share-embed *ngIf="currentShareView === PlaylistSharingViews.EMBED" class="shareView" [defaultAutoplay]="false" [playlist]="{id: playlistId}">
	</vb-ui-share-embed>
</section>
