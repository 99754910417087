import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

import { PipesAngularModule } from 'rev-shared/pipes/Pipes.AngularModule';

import { EmbedPreviewService } from './EmbedPreview.Service';

import styles from './VbMediaEmbedPreview.module.less';

@Component({
	standalone: true,
	imports: [CommonModule, TranslateModule, PipesAngularModule],
	selector: 'vb-media-embed-preview',
	template: `
		<div class="text-align-center">
			<h5>{{'EmbedPreview' | translate}}</h5>
		</div>

		<div [ngClass]="styles.previewWrap">
			<div [ngClass]="isResponsive ? styles.responsivePreview : styles.embedPreviewContainer">
				<iframe
					[width]="isResponsive ? '100%' : width"
					[height]="isResponsive ? '100%' : height"
					[src]="src | safeUrl"
					frameborder="0"
					allowfullscreen>
				</iframe>
			</div>
		</div>
	`,
})
export class VbMediaEmbedPreviewComponent implements OnInit, OnDestroy {
	public readonly styles = styles;

	public isResponsive: boolean;
	public width: number;
	public height: number;
	public src: string;
	private sub: Subscription;

	constructor(
		public EmbedPreviewService: EmbedPreviewService
	) { }

	public ngOnInit(): void {
		this.sub = this.EmbedPreviewService.data$.subscribe(d => {
			this.isResponsive = d.isResponsive;
			this.height = d.height;
			this.width = d.width;
			this.src = d.src;
		});
	}

	public ngOnDestroy(): void {
		this.sub.unsubscribe();
	}
}
