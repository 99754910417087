import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import './playlist-sharing.less';

enum PlaylistSharingViews {
	LINK = 'Link',
	EMBED = 'Embed'
}

@Component({
	selector: 'playlist-sharing',
	templateUrl: './PlaylistSharing.Component.html'
})
export class PlaylistSharing implements OnInit {
	@Input() public embedsEnabled: boolean;
	@Input() public playlistId: string;
	@Input() public playlistName: string;
	@Input() public playlistEmbedUrl: string;
	@Input() public playlistPlaybackUrl: string;

	public readonly PlaylistSharingViews = PlaylistSharingViews;

	public currentShareView: string = PlaylistSharingViews.LINK;
	public playlistSharingEmailBody: string;
	public playlistSharingEmailSubject: string;

	constructor(
		private Translate: TranslateService
	) {}

	public ngOnInit(): void {
		this.playlistSharingEmailBody = this.getEmailBody();
	}

	protected getEmailBody(): string {
		// mailTo does not work with html body well. So, getting the plain text rather than html...
		return `${this.Translate.instant('Media_Videos_Playlists_Sharing_Email_Intro')}\n\n${this.playlistName}\n${this.playlistPlaybackUrl}`;
	}
}
