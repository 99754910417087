<ng-template [vbCssRules]="scopedCSSRules"></ng-template>

<div flex="fill" dropdown #mobileMenuDropdown="bs-dropdown">
	<button dropdownToggle id="mainMenu" type="button" class="show-nav animated btn main-nav-btn btn-mobile theme-accent-bg dropdown-toggle" [ngClass]="{ 'btn-mobile-open bounceInLeft': mobileMenuDropdown.isOpen }" [attr.aria-label]="'UI_MainMenu' | translate" [attr.aria-pressed]="!!mobileMenuDropdown.isOpen" (click)="onToggleClick()">
		<span class="glyphicons show_lines theme-accent-txt animated" [ngClass]="{
				'show_lines': !mobileMenuDropdown.isOpen,
				'remove_2 flip': mobileMenuDropdown.isOpen
			}">
		</span>
	</button>
	<div *dropdownMenu class="dropdown-menu navdrop-menu mobile-menu util-break-word" [ngClass]="{'theme-header-bg' : isNettrixTheme}">
		<div class="container-fluid height-full accent-border theme-accent-border" [ngClass]="{'theme-header' : isNettrixTheme}">
			<ul class="mobile-menu-list" role="menu" aria-labelledby="mainMenu">
				<ng-container *ngIf="!isNettrixTheme">
					<li class="search-cell" *vbAuthorizationKey="'distribution.authenticated'; fallback true" (click)="$event.stopPropagation()">
						<search-header></search-header>
					</li>
				</ng-container>
				<li class="mobile-menu-list-item" [ngClass]="{'theme-accent-btn' : isNettrixTheme}" uiSrefActive="active" *vbAuthorizationKey="'authenticated'" role="none">
					<a class="dropdown-item" [uiSref]="landingState.state" [uiParams]="landingState.params" (click)="subMenu = undefined;" data-toggle="tab" role="menuitem">
						{{ 'Home' | translate }}
					</a>
				</li>

				<li class="mobile-menu-list-item" [ngClass]="{'isOpen' : showVideoMenu,
								'active': $state.includes('portal.media') && !showVideoMenu,
								'theme-accent-btn' : isNettrixTheme}" *vbAuthorizationKey="'authenticated'" role="none">
					<button type="button" class="dropdown-item" (click)="onVideoMenuClick(); $event.stopPropagation()" [ngClass]="{'active' : showVideoMenu}" role="menuitem">
						{{ 'Media_Videos' | translate }}
						<i class="pull-right glyphicon animated menu-item-icon" [ngClass]="{'glyphicon-chevron-right' : !showVideoMenu,
										'glyphicon-chevron-down flipInX' : showVideoMenu,
										'theme-accent-btn' : isNettrixTheme}">
						</i>
					</button>

					<my-videos-menu-items *ngIf="showVideoMenu"></my-videos-menu-items>
				</li>

				<li class="mobile-menu-list-item" [ngClass]="{'theme-accent-btn' : isNettrixTheme}" uiSrefActive="active" *vbAuthorizationKey="'authenticated'" role="none">
					<a uiSref="portal.team" class="dropdown-item" (click)="subMenu = undefined;" data-toggle="tab" role="menuitem">
						{{ 'Teams_Title' | translate }}
					</a>
				</li>

				<li class="mobile-menu-list-item" [ngClass]="{'theme-accent-btn' : isNettrixTheme}" uiSrefActive="active" *vbAuthorizationKey="['authenticated', 'distribution.authenticated']" role="none">
					<a class="dropdown-item" uiSref="portal.scheduledEvents.all" [uiOptions]="{ inherit: false }" (click)="subMenu = undefined;" data-toggle="tab" role="menuitem">
						{{ 'Events' | translate }}
					</a>
				</li>

				<li class="mobile-menu-list-item" [ngClass]="{'theme-accent-btn' : isNettrixTheme}" uiSrefActive="active" *vbAuthorizeState="'portal.admin'" role="none">
					<a class="dropdown-item" uiSref="portal.load-admin" [uiParams]="{accountId: accountId}" (click)="subMenu = undefined;" data-toggle="tab" role="menuitem">
						{{ (isAnalystAdmin ? 'Analytics' : 'Admin') | translate }}
					</a>
				</li>

				<ng-container *ngIf="!account.readonlyUserProfile">
					<li class="mobile-menu-list-item" uiSrefActive="active" [ngClass]="{'theme-accent-btn' : isNettrixTheme}" *vbAuthorizationKey="['authenticated', 'distribution.authenticated']" role="none">
						<a class="dropdown-item" uiSref="portal.userProfile" (click)="subMenu = undefined;" data-toggle="tab" role="menuitem">
							{{ 'UserProfile_MyProfile' | translate }}
						</a>
					</li>
				</ng-container>

				<ng-container *ngIf="showManageSubscriptions">
					<li *vbAuthorizationKey="['authenticated']" class="mobile-menu-list-item" [ngClass]="{'theme-accent-btn' : isNettrixTheme}">
						<a [uiSref]="'portal.manageSubscriptions'" class="dropdown-item line-height-1">
							{{ 'ManageSubscriptions' | translate }}
						</a>
					</li>
				</ng-container>

				<li class="mobile-menu-list-item" [ngClass]="{'isOpen' : showHelpMenu,
								'theme-accent-btn' : isNettrixTheme}" *vbAuthorizationKey="['authenticated', 'distribution.authenticated']" role="none">
					<help-menu-mobile [ngModel]="subMenu" [currentTheme]="theme">
					</help-menu-mobile>
				</li>

				<li class="mobile-menu-list-item" [ngClass]="{'theme-accent-btn' : isNettrixTheme}" *vbAuthorizationKey="['authenticated', 'distribution.authenticated']" role="none">
					<a class="dropdown-item" uiSref="logout" role="menuitem">
						{{ 'Logout' | translate }}
					</a>
				</li>

				<li class="mobile-menu-list-item" [ngClass]="{'theme-accent-btn' : isNettrixTheme}" *vbAuthorizationKey="['authenticated', 'distribution.authenticated']; fallback true" role="none">
					<button type="button" class="dropdown-item" (click)="loginRedirect()" tabindex="0" role="menuitem">
						{{ 'SignIn' | translate }}
					</button>
				</li>
			</ul>
		</div>
	</div>
</div>
