<textarea [ngClass]="styles.txtArea" readonly="readonly" rows="4" [value]="embedCode" [attr.aria-label]="'Media_Videos_Sharing_Embed' | translate" vbUiTextArea #clipboardTarget>
</textarea>

<div class="padding-top-5 width-full" [ngClass]="styles.embedActions">
	<button [ngClass]="styles.toolbarBtn" (click)="resetEmbedOptions()">
		{{ 'Reset' | translate }}
	</button>
	<button *ngxClipboardIfSupported [ngClass]="styles.toolbarBtn" [ngxClipboard]="clipboardTarget">
		{{ 'Media_Videos_Sharing_Copy' | translate }}
	</button>
</div>

<div [ngClass]="styles.actionWrapper" layout-wrap>
	<section [ngClass]="styles.embedOptionWrapper" display="flex" layout="column" ngForm>

		<div flex="fill" class="margin-bottom-10">{{ 'Media_Videos_Embed_EmbedOptions' | translate }}</div>

		<div *ngIf="video" layout="row" flex-align="center" class="margin-bottom-10">
			<vb-toggle-button name="showStartAt" [ariaLabel]="'Media_Videos_Sharing_StartAt' | translate" [(ngModel)]="showStartAt" (ngModelChange)="onStartAtToggleChange()">
			</vb-toggle-button>

			<label for="txtStartAt">{{ 'Media_Videos_Sharing_StartAt' | translate }}</label>
			<div *ngIf="showStartAt" [ngClass]="styles.startAt">
				<input type="text" name="startAt" id="txtStartAt" #startAtInput="ngModel" [(ngModel)]="startAt" (ngModelChange)="updateEmbedData()" vbUiTextInput vbTimespanInput [includeHours]="true" [vbMin]="0"/>
				<div *ngIf="startAtInput.invalid" role="alert" vbUiErrorMessage>
					<div *ngIf="startAtInput.errors.timespan">
						{{ 'Media_Videos_Sharing_InvalidStartAt' | translate : { '0': 'hh:mm:ss' } }}
					</div>
				</div>
			</div>
		</div>

		<div layout="row">
			<button type="button" class="btn-transparent" [ngClass]="styles.embedOptionCategory" (click)="activateMenu(menus.Layout)">
				{{ 'Layout' | translate }}
				<span class="glyphicon glyphicon-chevron-right" [ngClass]="{'glyphicon-chevron-down': activeMenu == menus.Layout}"></span>
			</button>
		</div>

		<div *ngIf="activeMenu === menus.Layout">
			<div layout="row" *ngIf="video">
				<vb-toggle-button name="showVideoOnly" [toggleId]="'ShowVideoOnly'" [ariaLabel]="'Media_Videos_Sharing_LinkType' | translate" [(ngModel)]="isPopupPlayer" (ngModelChange)="updateEmbedData()">
				</vb-toggle-button>

				<label [ngClass]="styles.label" flex="fill">
					{{ 'Media_Videos_Sharing_EmbedType' | translate }}
					<vb-ui-tooltip [iconName]="'circle_question_mark'" [placement]="'top'" [text]="'Media_Videos_Sharing_EmbedType_Tooltip' | translate">
					</vb-ui-tooltip>
				</label>
			</div>

			<div layout="row" class="margin-top-10">
				<div [ngClass]="styles.playerSize">
					<label [ngClass]="styles.label" for="embedSize">{{ 'Media_Videos_Embed_Size' | translate }}</label>
				</div>
				<div [ngClass]="styles.playerDimensions" *ngIf="sizeName !== 'responsive'">
					<label [ngClass]="styles.label" for="embedWidth">{{ 'Media_Videos_Embed_Width' | translate }}</label>
					<label [ngClass]="styles.label" for="embedHeight">{{ 'Media_Videos_Embed_Height' | translate }}</label>
				</div>
			</div>

			<div layout="row">
				<vb-ui-select [ngClass]="styles.playerSize">
					<select #selectElement id="embedSize" name="sizeSelect" [(ngModel)]="sizeName" (ngModelChange)="onSizeChange()">
						<ng-container *ngIf="video">
							<option [ngValue]="'small'">{{ 'Media_Videos_Embed_Small' | translate }}</option>
							<option [ngValue]="'medium'">{{ 'Media_Videos_Embed_Medium' | translate }}</option>
							<option [ngValue]="'large'">{{ 'Media_Videos_Embed_Large' | translate }}</option>
						</ng-container>
						<option [ngValue]="'responsive'">{{ 'Media_Videos_Embed_Responsive' | translate }}</option>
						<option [ngValue]="'custom'">{{ 'Media_Videos_Embed_Custom' | translate }}</option>
					</select>
				</vb-ui-select>
				<div *ngIf="sizeName !== 'responsive'" [ngClass]="styles.playerDimensions">
					<input type="number" id="embedWidth" [ngClass]="styles.input" class="margin-right-5" name="width" min="1" step="1" pattern="\d+" required [placeholder]="'Media_Videos_Embed_Width' | translate" #widthInput="ngModel" [(ngModel)]="width" (ngModelChange)="onWidthSizeChange()" vbUiTextInput>
					<input type="number" disabled="disabled" id="embedHeight" [ngClass]="styles.input" name="height" [(ngModel)]="height" [placeholder]="'Media_Videos_Embed_Height' | translate" vbUiTextInput>

					<div *ngIf="widthInput.invalid" role="alert" vbUiErrorMessage>
						{{ 'MustContainNumberGreaterThan' | translate : { '0': 0 } }}
					</div>
				</div>
			</div>

			<div *ngIf="playlist">
				<div class="margin-bottom-10 margin-top-20">{{ 'Layout' | translate }}</div>
				<div layout="row">
					<div [ngClass]="styles.layoutBtn" *ngFor="let l of layouts">
						<button type="button" (click)="selectLayout(l.name)" [attr.aria-label]="l.name | translate" [ngClass]="l.name === layout ? styles.active : ''">
							<span class="vb-icon" [ngClass]="l.icon"></span>
						</button>
						<div [ngClass]="styles.btnLabel">{{l.title | translate}}</div>
					</div>
				</div>
				<div *ngIf="layout === 'grid'">
					<div [ngClass]="styles.inputRow">
						<label for="maxGridRow">
							{{ 'Media_Videos_Embed_MaxGridRow' | translate }}
						</label>
						<input type="number" id="maxGridRow" [ngClass]="styles.inputSmall" name="maxGridRow" min="3" max="5" step="1" pattern="\d+" required #maxGridModel="ngModel" [(ngModel)]="maxGridRow" (ngModelChange)="onMaxRowChange()" vbUiTextInput>
					</div>
					<div *ngIf="maxGridModel.invalid" role="alert" vbUiErrorMessage>
						{{ 'MustContainNumberRange' | translate : { '0': 3, '1': 5 } }}
					</div>
					<div [ngClass]="styles.inputRow">
						<label for="maxVideos" #uiLabel>
							{{ 'Media_Videos_Embed_MaxVideos' | translate }}
						</label>
						<input type="number" id="maxVideos" [ngClass]="styles.inputSmall" name="maxVideos" min="1" step="1" pattern="\d+" #maxVideosModel="ngModel" [(ngModel)]="maxVideos" (ngModelChange)="updateEmbedData()" vbUiTextInput>
					</div>
					<div *ngIf="maxVideosModel.invalid; else maxVideosInfo" role="alert" vbUiErrorMessage>
						{{ 'MustContainNonNegativeNumber' | translate }}
					</div>
					<ng-template #maxVideosInfo>
						<div [ngClass]="styles.info">
							{{ 'Media_Videos_Embed_MaxVideosInfo' | translate }}
						</div>
					</ng-template>

				</div>
			</div>
		</div>

		<div layout="row" *ngIf="video || layout !== 'row'">
			<button type="button" class="btn-transparent" [ngClass]="styles.embedOptionCategory" (click)="activateMenu(menus.Styling)">
				{{ 'Media_Videos_Embed_Styling' | translate }}
				<span class="glyphicon glyphicon-chevron-right" [ngClass]="{'glyphicon-chevron-down': activeMenu == menus.Styling}"></span>
			</button>
		</div>

		<div *ngIf="activeMenu == menus.Styling">

			<div layout="row" class="margin-bottom-10">
				<vb-toggle-button name="playerAccentColor" [toggleId]="'PlayerAccentColor'" [ariaLabel]="'Branding_FontColor' | translate" [(ngModel)]="useAccentColor" (ngModelChange)="updateEmbedData()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill"> {{ 'Branding_AccentColor' | translate }} </label>
				<vb-ui-color-selector *ngIf="useAccentColor" name="accentColorPicker" [(ngModel)]="accentColor" [defaultColor]="ThemeService.accentColor" (ngModelChange)="updateEmbedData()">
				</vb-ui-color-selector>
			</div>

			<ng-container *ngIf="playlist">

				<div layout="row" class="margin-bottom-10">
					<vb-toggle-button name="primaryColor" [toggleId]="'primaryColor'" [ariaLabel]="'Branding_PrimaryColor' | translate" [(ngModel)]="usePrimaryColor" (ngModelChange)="updateEmbedData()">
					</vb-toggle-button>
					<label [ngClass]="styles.label" flex="fill"> {{ 'Branding_PrimaryColor' | translate }} </label>
					<vb-ui-color-selector *ngIf="usePrimaryColor" name="primaryColorPicker" [(ngModel)]="primaryColor" [defaultColor]="ThemeService.primaryColor" (ngModelChange)="updateEmbedData()">
					</vb-ui-color-selector>
				</div>

				<div layout="row" class="margin-bottom-10">
					<vb-toggle-button name="primaryFontColor" [toggleId]="'primaryFontColor'" [ariaLabel]="'Branding_PrimaryFontColor' | translate" [(ngModel)]="usePrimaryFontColor" (ngModelChange)="updateEmbedData()">
					</vb-toggle-button>
					<label [ngClass]="styles.label" flex="fill"> {{ 'Branding_PrimaryFontColor' | translate }} </label>
					<vb-ui-color-selector *ngIf="usePrimaryFontColor" name="primaryFontColorPicker" [(ngModel)]="primaryFontColor" [defaultColor]="ThemeService.primaryFontColor" (ngModelChange)="updateEmbedData()">
					</vb-ui-color-selector>
				</div>
			</ng-container>
		</div>

		<div layout="row" *ngIf="video || layout === 'slider'">
			<button type="button" class="btn-transparent" [ngClass]="styles.embedOptionCategory" (click)="activateMenu(menus.Controls)">
				{{ 'Media_Videos_Embed_Controls' | translate }}
				<span class="glyphicon glyphicon-chevron-right" [ngClass]="{'glyphicon-chevron-down': activeMenu == menus.Controls}"></span>
			</button>
		</div>

		<div *ngIf="activeMenu == menus.Controls">

			<div layout="row">
				<vb-toggle-button name="allControlsToggle" [toggleId]="'AllControlsToggle'" [ariaLabel]="'Media_Videos_Embed_AllControls' | translate" [(ngModel)]="allControlsToggle" (ngModelChange)="onAllControlsToggleChange()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Media_Videos_Embed_AllControls' | translate }}</label>
			</div>

			<div layout="row">
				<vb-toggle-button name="centerButtonsToggle" [toggleId]="'centerButtons'" [ariaLabel]="'Media_Videos_Embed_CenterButtons' | translate" [(ngModel)]="controls.centerButtons" (ngModelChange)="checkToggle()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Media_Videos_Embed_CenterButtons' | translate }}</label>
			</div>

			<div layout="row">
				<vb-toggle-button name="playBarToggle" [toggleId]="'playBar'" [ariaLabel]="'Media_Videos_Embed_PlayBar' | translate" [(ngModel)]="controls.playBar" (ngModelChange)="checkToggle()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Media_Videos_Embed_PlayBar' | translate }}</label>
			</div>

			<div layout="row">
				<vb-toggle-button name="closedCaptionsToggle" [toggleId]="'ClosedCaptions'" [ariaLabel]="'Media_Videos_Embed_ClosedCaptions' | translate" [(ngModel)]="controls.closedCaptions" (ngModelChange)="checkToggle()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Media_Videos_Embed_ClosedCaptions' | translate }}</label>
			</div>

			<div layout="row" *ngIf="ccEnabled">
				<vb-toggle-button name="settingsToggle" [toggleId]="'Settings'" [ariaLabel]="'Settings' | translate" [(ngModel)]="controls.settings" (ngModelChange)="checkToggle()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Settings' | translate }}</label>
			</div>

			<div layout="row">
				<vb-toggle-button name="fullscreenToggle" [toggleId]="'Fullscreen'" [ariaLabel]="'Media_Videos_Embed_Fullscreen' | translate" [(ngModel)]="controls.fullscreen" (ngModelChange)="checkToggle()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Media_Videos_Embed_Fullscreen' | translate }}</label>
			</div>
		</div>

		<div layout="row">
			<button type="button" class="btn-transparent" [ngClass]="styles.embedOptionCategory" (click)="activateMenu(menus.Playback)">
				{{ 'Media_Videos_Embed_Playback' | translate }}
				<span class="glyphicon glyphicon-chevron-right" [ngClass]="{'glyphicon-chevron-down': activeMenu == menus.Playback}"></span>
			</button>
		</div>

		<div *ngIf="activeMenu == menus.Playback">


			<div layout="row" *ngIf="video && !video.isLive">
				<vb-toggle-button name="loopVideo" [toggleId]="'LoopVideo'" [ariaLabel]="'Media_Videos_Embed_Loop' | translate" [(ngModel)]="playback.loopVideo" (ngModelChange)="updateEmbedData()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Media_Videos_Embed_Loop' | translate }}</label>
			</div>

			<div layout="row">
				<vb-toggle-button name="autoplay" [toggleId]="'Autoplay'" [ariaLabel]="'Media_Autoplay' | translate" [(ngModel)]="playback.autoplay" (ngModelChange)="updateEmbedData()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Media_Autoplay' | translate }}</label>
			</div>
			<ng-template *ngIf="video">
				<div layout="row" *ngIf="chaptersEnabled">
					<vb-toggle-button name="hideChapters" [toggleId]="'HideChapters'" [ariaLabel]="'Media_Videos_Embed_HideChapters' | translate" [(ngModel)]="playback.hideChapters" (ngModelChange)="updateEmbedData()">
					</vb-toggle-button>
					<label [ngClass]="styles.label" flex="fill">{{ 'Media_Videos_Embed_HideChapters' | translate }}</label>
				</div>

				<div layout="row" *ngIf="ccEnabled">
					<vb-toggle-button name="forceClosedCaptions" [toggleId]="'ForceClosedCaptions'" [ariaLabel]="'Media_Videos_Embed_ForceClosedCaptions' | translate" [(ngModel)]="playback.forceClosedCaptions" (changModelChangenge)="updateEmbedData()">
					</vb-toggle-button>
					<label [ngClass]="styles.label" flex="fill">{{ 'Media_Videos_Embed_ForceClosedCaptions' | translate }}</label>
				</div>
			</ng-template>
		</div>
	</section>
</div>

