import { Component, ElementRef, ViewChild } from '@angular/core';

import type { ICellRendererAngularComp } from '@ag-grid-community/angular';
import type { Column, ColumnApi, GridApi, ICellRendererParams, RowNode } from '@ag-grid-community/core';

import styles from './VideoTitleCellRenderer.Component.module.less';

export interface IVideoTitleRendererCfg {
	uiSref: string;
	uiSrefParams?: any;
	ariaLabel?: string;
	coverFullCell?: boolean;
}

export interface IVideoTitleCellRendererParams {
	getCellCfg(rowData: any): IVideoTitleRendererCfg;
}

interface IMergedVideoTitleCellRendererParams extends ICellRendererParams, IVideoTitleCellRendererParams {}

@Component({
	selector: 'video-title-cell-renderer',
	templateUrl: './VideoTitleCellRenderer.Component.html',
	host: {
		'[class]': 'styles.root',
		'[class.height-full]': 'config.coverFullCell'
	}
})
export class VideoTitleCellRendererComponent implements ICellRendererAngularComp {
	public video: any;
	public context: any = {};
	public readonly styles = styles;
	public rowNode: RowNode;
	public columnApi: ColumnApi;
	public column: Column;
	public api: GridApi;
	public config: IVideoTitleRendererCfg;
	public videoEditAuth: boolean;

	@ViewChild('speechSearchList', { read: ElementRef })
	public speechSearchList: ElementRef<HTMLElement>;


	constructor(
		private elementRef: ElementRef<HTMLElement>
	){}

	public agInit(params: IMergedVideoTitleCellRendererParams): void {
		this.updateCell(params);
	}

	public refresh(params: IMergedVideoTitleCellRendererParams): boolean {
		this.updateCell(params);

		return true;
	}

	private updateCell(params: IMergedVideoTitleCellRendererParams): void {
		//sometimes grid data is null while grid is loading.
		this.video = params.data || {};
		this.config = params.getCellCfg(this.video);
		this.context = params.context;
		this.rowNode = params.node;
		this.columnApi = params.columnApi;
		this.column = params.column;
		this.api = params.api;
		this.videoEditAuth = params.context.hasMediaEditAuth || this.video.editAcl?.includes(this.context.userId);
	}

	public toggleRowExpand(isExpanded: boolean): void {
		setTimeout(() => {
			let height;
			if (isExpanded) {
				height = this.speechSearchList.nativeElement.clientHeight + this.elementRef.nativeElement.querySelector('a').clientHeight + 20;
			} else {
				height = this.context.getRowHeight();
			}
			this.rowNode.data.subtitleExpanded = isExpanded;
			this.rowNode.setRowHeight(height);
			const renderedRows = this.api.getRenderedNodes();

			const maxRowIndex = renderedRows.reduce((maxIndex, currentObj) => {
				if (currentObj.rowIndex > maxIndex) {
					return currentObj.rowIndex;
				}
				return maxIndex;
			}, -Infinity);

			for(let i = this.rowNode.rowIndex + 1; i <= maxRowIndex; i++) {
				const previousNode = renderedRows.find(node => node.rowIndex === (i - 1));
				const changedNode = renderedRows.find(node => node.rowIndex === i);

				const prevRowHeight = previousNode.rowHeight;
				const prevRowTop = previousNode.rowTop;

				changedNode.setRowTop(prevRowTop + prevRowHeight);
			}

			/* eslint-disable */
			const gridDiv: HTMLElement = this.api['gridOptionsWrapper']?.['eGridDiv'];
			const centerContainer: HTMLElement = gridDiv.querySelector('[ref="centerContainer"]');
			const totalRowHeights = this.totalRowHeights(renderedRows);
			if (centerContainer.offsetHeight < totalRowHeights) {
				centerContainer.style.height = totalRowHeights + 'px';
				this.api.onRowHeightChanged();
			}
		});
	}

	private totalRowHeights(renderedRows: RowNode[]): number {
		let height = 0;
		renderedRows.forEach(node => {
			height = node.rowHeight + height;
		})
		return height;
	}
}
